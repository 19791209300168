/* eslint-disable camelcase */
import React, { useState, useEffect, useContext, useRef } from 'react';
// material
import { styled } from '@mui/material/styles'
import { Container, Typography, Grid, Box, Card, Button, Backdrop, CircularProgress } from '@mui/material'
import { Icon } from '@iconify/react';
//
import { motion } from 'framer-motion';

// import { UsersContext } from '../../../contexts/UserContext';

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  position: 'relative',
  paddingTop: 42,
  paddingBottom: 62,
  display: 'flex',
  alignItems: 'center'
}));

export default function ApplySuccess({ data }) {
  const [loading, setLoading] = useState(false);
//   const { setUser } = useContext(UsersContext);
  const canvasRef = useRef(null);
  const [hasDownloaded, setHasDownloaded] = useState(() => {
    return localStorage.getItem('credentialsDownloaded') === 'true'
  });

//   const handleLogin = async () => {
//     setLoading(true);
//     try {
//       if (!data.username || !data.reference_id) return setLoading(false);
//       const form = new FormData();
//       form.append('email', data.username);
//       form.append('password', data.reference_id);
//       const result = await user_api.sign_in_email(form);
//       if (!result.ok) {
//         alert('Invalid username or password');
//         return setLoading(false);
//       }
//       const payload = result.data.data;
//       await storage.storeUser(payload);
//       setUser(payload);
//       localStorage.setItem('applicantStatus', 1)
//     } catch (error) {
//       console.error(error);
//     }
//     setLoading(false);
//   };

//   useEffect(() => {
//     const load = () => {
//       const get_complete = localStorage.getItem('complete');

//       const parseData = JSON.parse(get_complete);
//       setData(parseData.data);
//       return '';
//     };

//     load();

//     // eslint-disable-next-line
//   }, []);

  useEffect(() => {
    if (!data.username || !data.reference_id || hasDownloaded) return;
    generateImage();
    localStorage.setItem('credentialsDownloaded', 'true');
  }, [data]);

  const generateImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Set canvas dimensions and styles
    canvas.width = 400;
    canvas.height = 200;
    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = "#000000";
    ctx.font = "16px Arial";
    ctx.fillText(`Email: ${data.username}`, 10, 50);
    ctx.fillText(`Password: ${data.reference_id}`, 10, 100);

    // Trigger download
    const image = canvas.toDataURL("image/png");
    const link = document.createElement('a');
    link.href = image;
    link.download = '7star_login_credentials.png';
    link.click();
  };

  return (
    <RootStyle>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container maxWidth="lg">
        <Card sx={{ my: 10, py: 10, px: 5 }}>
          <Box>
            <motion.div style={{ marginTop: 0, position: 'relative', textAlign: 'center' }}>
              <Icon
                icon="material-symbols:check-circle"
                width="128"
                height="128"
                color="common.black"
                sx={{ fontSize: 128, color: 'primary.main', my: 2 }}
              />
            </motion.div>
            <Typography
              component="h2"
              variant="h3"
              color="common.black"
              sx={{ textAlign: 'center', my: { xs: 2, md: 3 } }}
            >
              Awesome you have submitted your Job Application.
            </Typography>
          </Box>
          <Typography
              component="p"
              variant="h6"
              color="common.black"
              sx={{ textAlign: 'center', my: { xs: 2, md: 3 } }}
            >
              Please save your credentials below before proceeding.
              {/* <a href={process.env.REACT_APP_MEMBER_URI} target="_blank" rel="noreferrer">
                Member's page
              </a>{' '} */}
            </Typography>
          <Grid sx={{ width: '100%', textAlign: 'center' }}>
            <motion.div style={{ marginTop: 0, position: 'relative' }}>
              <Box sx={{ textAlign: 'left', width: { md: '50%', sm: '50%', xs: '100%' }, margin: 'auto', paddingBottom:'3vh' }}>
                <Typography variant="h5" component="h3" color="common.black" sx={{ my: 2, fontWeight: 400 }}>
                  Email address : <strong>{data && data.username}</strong>
                </Typography>
                <Typography variant="h5" component="h3" color="common.black" sx={{ my: 2, fontWeight: 400 }}>
                  Password: <strong> {data && data.reference_id}</strong>
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'center', width: { md: '50%', sm: '50%', xs: '100%' }, margin: 'auto' }}>
                <canvas ref={canvasRef} style={{ display: 'none' }} />
                <Button variant="contained" onClick={generateImage}>
                    Download Credentials as Image
                </Button>
              </Box>
            </motion.div>
          </Grid>
        </Card>
      </Container>
    </RootStyle>
  );
}
