import React from 'react'
import {Box} from '@mui/material'
import {styled} from '@mui/material/styles'
import { Helmet } from 'react-helmet';
import LoginHero from './loginHero'
import LoginForm from './loginForm';
import LoginContent from './loginContent';

// Components


const ContentStyle = styled('div')(({theme}) => ({
  overflow: 'visible',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}))

function MetaTags({ title, description, keywords }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}

export default function LoginPage({ metaData, applyPageData, handleLoggedIn}) {
  return (
    <>
    {/* <MetaTags title={metaData.title} description={metaData.description} keywords={metaData.keywords} /> */}
    <Box id="move_top">
      <ContentStyle>
        <LoginHero />
        <LoginForm handleLoggedIn={handleLoggedIn} />
        <LoginContent />
      </ContentStyle>
    </Box>
    </>
  )
}