import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import {
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Box,
    IconButton,
    Divider,
    Grid,
    Popover,
    FormHelperText,
    FormControlLabel,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material'
import { Icon } from '@iconify/react';
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import SecureLS from 'secure-ls'
import AddFieldDialog from './dialog/AddFieldDialog'
import EditFieldDialog from './dialog/EditFieldDialog';
import AgreementDialog from './dialog/AgreementDialog';
import { last, set } from 'lodash';
import { request_upload_url } from 'api/private/crm';
import { validate } from 'numeral';
import moment from 'moment';
import { fi } from 'date-fns/locale';

const image_bucket = process.env.REACT_APP_AWS_URI;

const ls = new SecureLS({encodingType: 'aes'})

const DynamicForm = ({ activeStep, onSubmit, review, updateFields, updateInputFields, handleBack }) => {
  const context = useContext(EditModeContext);
  const [formLayout, setFormLayout] = useState([]);
  const [feedBackMessage, setFeedBackMessage] = useState('');
  const [feedBackStatus, setFeedBackStatus] = useState('');
  const { isEditMode } = context || {};
  const [addFieldDialogOpen, setAddFieldDialogOpen] = useState(null);
  const [editFieldDialogOpen, setEditFieldDialogOpen] = useState(null);
  const [privacyPolicyDialogOpen, setPrivacyPolicyDialogOpen] = useState('');
  const [swornStatementDialogOpen, setSwornStatementDialogOpen] = useState('');
  const [allowSubmit, setAllowSubmit] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const popupRef = useRef(null);
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  const [avatarUrl, setAvatarUrl] = useState({});
  const [date, setDate] = useState();

  useEffect(() => {
    // let eighteenYearsAgo = new Date();
    // eighteenYearsAgo = eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear()-18);
    setDate(moment().format("YYYY-MM-DD"))
  }, []);

  useEffect(() => {
    let validatedFields = activeStep?.formLayout.map(field => validateField(field, field.value));
    setFormLayout(validatedFields);
    console.log('activeStep: ', activeStep)
  }, [activeStep]);

  useEffect(() => {
    if(formLayout.length > 0 && !review) {
      setAllowSubmit(checkFormValidity());  
    }  
  }, [formLayout, review]);

  const checkFormValidity = () => {
    const isNotValid = formLayout.some(field => {
      if (field.validations?.required && (field.type === 'text' || field.type === 'select' || field.type === 'image' || field.type === 'privacy-checkbox' || field.type === 'sworn-checkbox')) {
        if (field.type === 'privacy-checkbox' || field.type === 'sworn-checkbox') {
          return !field.value;
        } else {
          if (!field.conditional?.enabled) {
            return field.value.toString().trim() === '';
          }

          const dependentFieldValue = formLayout.find(f => f.name === field.conditional?.dependsOn)?.value;
          if (dependentFieldValue === field.conditional?.showWhen) {
            return field.value.toString().trim() === '';
          }
            return false
        }
      }
      return false;
    });
    return isNotValid;
  };

  const handleDropAvatar = async (acceptedFile, index) => {

    if (acceptedFile) {
      try {
        const url = await request_upload_url(acceptedFile);
        handleFieldChange(index, url);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const handlePopupOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    popupRef.current = index;
  };

  const handlePopupClose = () => {
    setAnchorEl(null);
    popupRef.current = null;
  };

  const openPopup = Boolean(anchorEl);
  const popupId = openPopup ? 'simple-popover' : undefined;

  const handleAddFields = (field, index) => {
    if (field?.type === 'text' || field?.type === 'select' || field?.type === 'image' || field?.type === 'privacy-checkbox' || field?.type === 'sworn-checkbox' || field?.type === 'checkbox' || field?.type === 'accordion-checkboxes') {
      const isLabelDuplicate = formLayout.some(existingField => existingField.name === field.name && field.name !== '');
      if (isLabelDuplicate) {
        setFeedBackMessage('A field with the same name already exists.');
        setFeedBackStatus('error');
        return;
      }
    }

    let updatedFormLayout;
    const addedFormLayout = { ...field };

    if (index === formLayout.length) {
      updatedFormLayout = [...formLayout, addedFormLayout];
    } else {
      updatedFormLayout = [...formLayout.slice(0, index), addedFormLayout, ...formLayout.slice(index)];
    }
    
    updateFields(updatedFormLayout);
    setFormLayout(updatedFormLayout);
    setFeedBackMessage('Field added successfully.');
    setFeedBackStatus('success');
  }

  const handleEditFields = (field, index) => {
    if (field?.type === 'text' || field?.type === 'select' || field?.type === 'image' || field?.type === 'privacy-checkbox' || field?.type === 'sworn-checkbox' || field?.type === 'checkbox' || field?.type === 'accordion-checkboxes') {
      const isLabelDuplicate = formLayout.some((existingField, existingIndex) => {
        return existingField.name === field?.name && field.name !== '' && existingIndex !== index;
      });
      if (isLabelDuplicate) {
        setFeedBackMessage('A field with the same name already exists.');
        setFeedBackStatus('error');
        return;
      }
    }
    const updatedFormLayout = [...formLayout];
    updatedFormLayout[index] = {...updatedFormLayout[index], ...field};
    updateFields(updatedFormLayout)
    setFeedBackMessage('Field edited successfully.');
    setFeedBackStatus('success');
    setFormLayout(updatedFormLayout)
  }

  const handleRemoveFields = (index) => {
    const updatedFormLayout = formLayout.filter((field, idx) => idx !== index);
    updateFields(updatedFormLayout)
    setAnchorEl(null);
    setFormLayout(updatedFormLayout)
  }

  const handleFieldChange = (index, value) => {
    const updatedForm = formLayout.map((field) => {
      if (index === formLayout.indexOf(field)) {
        return validateField(field, value);
      }
      return field;
    })
    console.log('updatedForm: ', updatedForm)
    updateInputFields(updatedForm);
    setFormLayout(updatedForm);
  }

  const handleDataPrivacyDialogOpen = (privacy, index) => {
    setPrivacyPolicyDialogOpen({...privacy, index});
  }

  const handleSwornStatementDialogOpen = (statement, index) => {
    setSwornStatementDialogOpen({...statement, index});
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(review) {
      onSubmit();
    } else {
      const formData = formLayout.reduce((acc, field) => {
        if (field.type === 'select' || field.type === 'text' || field.type === 'image') {
          acc[field.name] = field.value;
        }
        return acc;
      }, {})
      onSubmit(formData, activeStep.label);
    }
    // Here you would typically send the data to your backend
  }

  const validateField = (field, value) => {
    const errors = []; 
    let finalValue = value;

    if (field.validations.required && field.validations?.validationType !== 'numeric' && value.toString().trim() === '') {
      if (field.conditional?.enabled) {
        const dependentFieldValue = formLayout.find(f => f.name === field.conditional.dependsOn)?.value;
        if (dependentFieldValue === field.conditional.showWhen) {
          errors.push('This field is required');
        }
      } else {
        errors.push('This field is required');
      }
    } else if (field.validations.required && field.validations?.validationType === 'numeric' && value.toString().trim() === '') {
      finalValue = 0;
    }
  
    if (finalValue || finalValue === 0) {

      if (field.validations?.validationType === 'numeric') {

        if (field.validations?.minLength && parseInt(finalValue) < parseInt(field.validations?.minLength)) {
          finalValue = Number(field.validations?.minLength);
        }
    
        if (field.validations?.maxLength && parseInt(finalValue) > parseInt(field.validations?.maxLength)) {
          finalValue = Number(field.validations?.maxLength);
        }

        if (field.validations.required && parseInt(finalValue) === 0) {
          errors.push('This field is required');
        }

      } else {

        if (field.validations?.minLength && parseInt(finalValue.length) < field.validations?.minLength) {
          errors.push('Too short');
        }
    
        if (field.validations?.maxLength && finalValue.length > field.validations?.maxLength) {
          finalValue = finalValue.slice(0, field.validations?.maxLength);
        }

      }
      
      if (field.validations?.pattern && !new RegExp(field.validations?.pattern).test(finalValue)) {
        errors.push('Invalid format');
      }
  
      if (field.validations?.validationType === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(finalValue)) {
        errors.push('Invalid email format');
      }
  
      if (field.validations?.validationType === 'numeric' && !/^\d+$/.test(finalValue)) {
        errors.push('Must be numeric');
      }
      
    }

    console.log('errors: ', errors);
  
    return {
      ...field,
      value: finalValue,
      error: errors.length > 0 ? true : false,
      helperText: errors.join(', ')
    };
  };

  const renderField = (field, index) => {
      // Handle conditional display
    if (field.conditional?.enabled) {
      const dependentField = formLayout.find(f => f.name === field.conditional.dependsOn && f.type === field.conditional.type);
      if (dependentField !== undefined && dependentField?.value.toString() !== field.conditional?.showWhen.toString()) {
          return null;
      }
    }

    // Add repeatable field case
    if (field.repeatable?.enabled) {
      return (
        <Box sx={{ width: '100%' }}>
          <Typography sx={{ width: '100%' }} variant="subtitle1">{field.label}</Typography>
          {[...Array(field.value?.length || 1)].map((_, rowIndex) => (
            <>
            <Grid container spacing={2} key={rowIndex} sx={{ width: '100%' }}>
              {[...Array(Number(field.repeatable.columns))].map((_, colIndex) => (
                <Grid item xs={12 / field.repeatable.columns} key={colIndex} display={'flex'} flexDirection={'row'} marginBottom={2} sx={{ width: '100%' }}>
                  <TextField
                    fullWidth
                    label={field.repeatable.columnLabels[colIndex]}
                    name={field.repeatable.columnNames[colIndex]}
                    value={field.value?.[rowIndex]?.[colIndex] || ''}
                    onChange={(e) => {
                      const newValue = field.value ? [...field.value] : [];
                      if (!newValue[rowIndex]) newValue[rowIndex] = [];
                      newValue[rowIndex][colIndex] = e.target.value;
                      handleFieldChange(index, newValue);
                    }}
                    disabled={review}
                  />
                  {!review && colIndex === field.repeatable.columns - 1 && (
                    <>
                      {rowIndex === 0 && (
                        <Button
                          sx={{ ml: 2 }} 
                          size="large"
                          variant="contained"
                          onClick={() => {
                            if (field.value?.length >= field.repeatable.maxRows) return;
                            const newValue = [...(field.value || []), []];
                            handleFieldChange(index, newValue);
                          }}
                          disabled={field.value?.length >= field.repeatable.maxRows}
                        >
                          <Icon icon="eva:plus-circle-outline" width={20} height={20} />
                        </Button>
                      )}
                      {rowIndex > 0 && (
                        <Button 
                          sx={{ ml: 2 }} 
                          size="large"
                          variant="outlined"
                          onClick={() => {
                            const newValue = field.value.filter((_, i) => i !== rowIndex);
                            handleFieldChange(index, newValue);
                          }}
                        >
                          <Icon icon="eva:minus-circle-outline" width={20} height={20} />
                        </Button>
                      )}
                    </>
                  )}
                </Grid>
              ))}
            </Grid>
            </>
          ))}
        </Box>
      );
    }
    switch (field?.type) {
      case 'text':
        return (
          <TextField
            sx={{ width: '100%' }}
            name={field?.name}
            label={`${field?.label} ${field?.description ? `${field?.description}` : ''}`}
            type={field.validations?.validationType === 'numeric' ? 'number' : undefined}
            value={field.value}
            onChange={(e) => handleFieldChange(index, e.target.value)}
            required={field.validations?.required}
            error={field?.error}
            helperText={field?.helperText}
            multiline={field.style?.textarea}
            rows={field.style?.textarearows ?? undefined}
            inputProps={field.style?.textarea ? {
              maxLength: field.validations?.maxLength,
              minLength: field.validations?.minLength,
              pattern: field.validations?.pattern ? field.validations?.pattern : undefined,
              style: {
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                wordWrap: 'break-word',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }
            } : {
              maxLength: field.validations?.maxLength,
              minLength: field.validations?.minLength,
              pattern: field.validations?.pattern ? field.validations?.pattern : undefined,
            }}
            disabled={review}
          />
        )
      case 'label':
        return (
          <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
            {field.label}
          </Typography>
        )
      case 'select':
        return (
          <>
          <FormControl sx={{ width: `100%` }} error={field?.error}>
            <InputLabel id={field?._id}>{field.validations.required ? `${field.label} *` : field.label}</InputLabel>
            <Select
              labelId={field?._id}
              required={field.validations.required}
              value={field?.value}
              label={field.label}
              name={field?.name}
              onChange={(e) => handleFieldChange(index, e.target.value)}
              disabled={review}
            >
              <MenuItem value="" disabled>Select</MenuItem>
              {field.options?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{field?.helperText}</FormHelperText>
          </FormControl>
          </>
        )
      case 'image':
        return (
          <Box>
            <input
              accept={field.validations?.allowedFileTypes || "image/*"}
              style={{ display: 'none' }}
              id={`image-upload-${field?._id}`}
              name={field?.name}
              type="file"
              onChange={(e) => {
                const file = e.target.files?.[0]
                if (file) {
                  if (field.validations?.maxSize && file.size > field.validations?.maxSize) {
                    return
                  }
                  handleDropAvatar(file, index)
                }
              }}
              required={field.validations.required}
              tabIndex="0"
            />
            <label htmlFor={`image-upload-${field?._id}`}>
              <Button 
                name={`image-upload-${field?._id}`} 
                variant="contained" 
                component="span"
                tabIndex="0"
                role="button"
                onChange={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    document.getElementById(`image-upload-${field?._id}`).click()
                  }
                }}
                disabled={review}
              >
                Upload
              </Button>
            </label>
            {field?.helperText && <FormHelperText error={field?.error}>{field?.helperText}</FormHelperText>}
            {field.value && (
              <Box mt={2}>
                <img src={field?.value ? `${image_bucket}/${field?.value}` : ''} alt="Image here" style={{ maxWidth: '100%', maxHeight: '200px' }} />
              </Box>
            )}
          </Box>
          )
      case 'divider':
        return <Divider />
      case 'divider-label':
        return <Divider sx={{width: '100%'}}> {field.label} </Divider>
      case 'privacy-checkbox':
        return (
          <FormControlLabel
            sx={{ mb: 3, mt: '0 !important'}}
            checked={field.value}
            control={<Checkbox 
              color="primary" 
              onChange={(e) => {handleFieldChange(index, e.target.checked)}}
              name={field?.name} 
              disabled={review}
              />
            }
            
            label={
              <Typography
                variant="body2"
                align="left"
                sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', mt: '0 !important' }}
              >
                By using this form you agree 
                with the storage and handling of your data by 
                this website in accordance with our
                <Button
                  variant="text"
                  onClick={() => handleDataPrivacyDialogOpen(field, index)}
                  sx={{
                    p: '0 !important',
                    height: 'auto !important',
                    minWidth: 'auto !important',
                    ml: 1,
                    color: 'blue.main'
                  }}
                >
                  Data Privacy
                </Button>
              </Typography>
            }
          />
        )
      case 'sworn-checkbox':
        return (
          <FormControlLabel
            sx={{ mb: 1, mt: 1 }}
            checked={field.value}
            control={<Checkbox color="primary" name={field?.name} onChange={(e) => {handleFieldChange(index, e.target.checked)}} disabled={review} />}
            label={
              <Typography
                variant="body2"
                align="left"
                sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', mt: '0 !important' }}
              >
                I have read the{' '}
                <Button
                  variant="text"
                  onClick={() => handleSwornStatementDialogOpen(field, index)}
                  sx={{
                    p: '0 !important',
                    height: 'auto !important',
                    minWidth: 'auto !important',
                    ml: 1,
                    color: 'blue.main'
                  }}
                >
                  Sworn statement
                </Button>
              </Typography>
            }
          />
        )
      case 'accordion-checkboxes':
        return (
          <Accordion sx={{ width: '100%' }} expanded={review ? true : undefined}>
            <AccordionSummary
              expandIcon={<Icon icon={'ic:baseline-expand-more'} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{field?.label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {field?.options.map((option) => (
                 <Typography>
                  <FormControlLabel
                    control={<Checkbox color="primary" disabled={review} />}
                    label={option}
                  />
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        )
      case 'checkbox':
        return (
          <FormControlLabel
            sx={{ mb: 1, mt: 1 }}
            checked={field?.value === true}
            value={field?.value}
            name={field?.name}
            control={<Checkbox color="primary" onChange={(e) => {handleFieldChange(index, e.target.checked)}} value={field?.value} checked={field?.value === true} disabled={review} />}
            label={field.label}
          />
        )
      case 'date-picker' :
        return (
          <TextField
            fullWidth
            type="date"
            label={field?.label}
            InputProps={{ inputProps: { min: '1900-12-31', max: date } }}
            onChange={(e) => {handleFieldChange(index, e.target.value)}}
            value={field?.value}
            disabled={review}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 1, mt: 1 }}
            error={field?.error}
            helperText={field?.helperText}
            required={field?.validations.required}
          />
        )
      default:
        return !!isEditMode ? (<Divider>Next Row</Divider>) : null
    }
  }

  const handleAddFieldDialogClose = () => {
    setAddFieldDialogOpen(null);
    setFeedBackMessage('');
    setFeedBackStatus('');
  };

  const handleAddFieldDialogOpen = (index) => {
    setAddFieldDialogOpen(index);
    setAnchorEl(null);
  };

  const handleEditFieldDialogOpen = (index) => {
    setEditFieldDialogOpen(formLayout[index]);
  }

  const handleEditFieldDialogClose = () => {
    setEditFieldDialogOpen(null);
    setFeedBackMessage('');
    setFeedBackStatus('');
  }

  const handleMoveFieldToRight = (index) => {
    const updatedFormLayout = [...formLayout];
    const stepToMove = updatedFormLayout[index];
    const nextIndex = (index + 1) % updatedFormLayout.length;
    
    updatedFormLayout[index] = updatedFormLayout[nextIndex];
    updatedFormLayout[nextIndex] = stepToMove;
    
    setFormLayout(updatedFormLayout);
    updateFields(updatedFormLayout);
    setAnchorEl(null);
  }

  const handleMoveFieldToLeft = (index) => {
    const updatedFormLayout = [...formLayout];
    const stepToMove = updatedFormLayout[index];
    const nextIndex = (index - 1 + updatedFormLayout.length) % updatedFormLayout.length;
    
    updatedFormLayout[index] = updatedFormLayout[nextIndex];
    updatedFormLayout[nextIndex] = stepToMove;
    
    setFormLayout(updatedFormLayout);
    updateFields(updatedFormLayout);
    setAnchorEl(null);
  }

  const handlePrivacyConfirm = (field) => {
    setPrivacyPolicyDialogOpen('');
    handleFieldChange(field?.index, true)
  }

  const handleSwornConfirm = (field) => {
    setSwornStatementDialogOpen('');
    handleFieldChange(field?.index, true)
  }

  return (
    <Box>
        <AddFieldDialog open={addFieldDialogOpen} onClose={handleAddFieldDialogClose} onSubmit={handleAddFields} status={feedBackStatus} message={feedBackMessage} />
        <EditFieldDialog field={editFieldDialogOpen} fieldIndex={popupRef.current} onClose={handleEditFieldDialogClose} onSubmit={handleEditFields} status={feedBackStatus} message={feedBackMessage} />
        <AgreementDialog open={privacyPolicyDialogOpen || swornStatementDialogOpen} onConfirm={privacyPolicyDialogOpen ? handlePrivacyConfirm : handleSwornConfirm} />
        <form onSubmit={handleSubmit}>
        <Grid container sx={{ width: '100%' }}>
            {formLayout.map((field, index) => {
              const renderedField = renderField(field, index)
              if (renderedField !== null) return (
              field?.type === 'divider' || field?.type === 'divider-label' || field?.type === 'next-row' || field?.type === 'accordion-checkboxes' || field?.repeatable?.enabled ? (
                <Grid item xs={12} key={field?._id} sx={{ mb: field?.type === 'next-row' ? 2 : undefined, my: field?.type !== 'next-row' ? 2 : undefined }}>
                   <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                      {renderField(field, index)}
                    </Box>
                    {!!isEditMode && (
                      <Box ml={2}>
                        <IconButton onClick={() => handleMoveFieldToLeft(index)}>
                          <Icon icon="ic:sharp-arrow-back" />
                        </IconButton>
                        <IconButton aria-describedby={popupId} onClick={(e) => handlePopupOpen(e, index)}>
                          <Icon icon="mdi:dots-vertical" />
                        </IconButton>
                        <Popover
                          id={popupId}
                          open={openPopup}
                          anchorEl={anchorEl}
                          onClose={handlePopupClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <Box display={"flex"} flexDirection={"column"} alignItems="flex-start">
                            <Button startIcon={<Icon icon="mdi:close" width={20} height={20} />} sx={{ justifyContent: 'flex-start' }} onClick={() => handleRemoveFields(popupRef.current)} color="error">
                              Remove Field
                            </Button>
                            <Button startIcon={<Icon icon="mdi:plus" width={20} height={20} />} sx={{ justifyContent: 'flex-start' }} onClick={() => handleAddFieldDialogOpen(popupRef.current)} color="primary">
                              Add Field After
                            </Button>
                            <Button startIcon={<Icon icon="mdi:pencil" width={20} height={20} />} sx={{ justifyContent: 'flex-start' }} onClick={() => handleEditFieldDialogOpen(popupRef.current)} color="primary">
                              Edit Field
                            </Button>
                          </Box>
                        </Popover>
                        <IconButton onClick={() => handleMoveFieldToRight(index)}>
                          <Icon icon="ic:sharp-arrow-forward" width={20} height={20} />
                        </IconButton>
                      </Box>
                    )}
                    </Box>
                </Grid>
              ) : (
                <Box sx={{ width: `${field.style?.width ?? 100}%` }} key={field?._id} display="flex" alignItems="center" mr={2} mb={2}>
                  <Box flexGrow={1}>
                    {renderField(field, index)}
                  </Box>
                  {!!isEditMode && (
                    <Box>
                      <IconButton onClick={() => handleMoveFieldToLeft(index)}>
                        <Icon icon="ic:sharp-arrow-back" />
                      </IconButton>
                      <IconButton aria-describedby={popupId} onClick={(e) => handlePopupOpen(e, index)}>
                        <Icon icon="mdi:dots-vertical" />
                      </IconButton>
                      <Popover
                        id={popupId}
                        open={openPopup}
                        anchorEl={anchorEl}
                        onClose={handlePopupClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        elevation={0}
                        slotProps={{
                          boxShadow: {
                            
                          }
                        }}
                      >
                        <Box display={"flex"} flexDirection={"column"} alignItems="flex-start">
                          <Button startIcon={<Icon icon="mdi:close" width={20} height={20} />} sx={{ justifyContent: 'flex-start' }} onClick={() => handleRemoveFields(popupRef.current)} color="error">
                            Remove Field
                          </Button>
                          <Button startIcon={<Icon icon="mdi:pencil" width={20} height={20} />} sx={{ justifyContent: 'flex-start' }} onClick={() => handleEditFieldDialogOpen(popupRef.current)} color="primary">
                            Edit Field
                          </Button>
                          <Button startIcon={<Icon icon="mdi:plus" width={20} height={20} />} sx={{ justifyContent: 'flex-start' }} onClick={() => handleAddFieldDialogOpen(popupRef.current)} color="primary">
                            Add Field After
                          </Button>
                        </Box>
                      </Popover>
                      <IconButton onClick={() => handleMoveFieldToRight(index)}>
                        <Icon icon="ic:sharp-arrow-forward" />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              )
            )})}
            </Grid>
            <Box display="flex" flexDirection={'column'} justifyContent={'center'} sx={{ mb: 4 }}>
                { formLayout.length === 0 && (
                  <Typography align="center">No field/s added.</Typography>
                )}
                {!!isEditMode && (
                  <IconButton onClick={() => handleAddFieldDialogOpen(formLayout.length - 1)} variant="contained">
                    <Icon icon="mdi:plus" />
                  </IconButton>
                )}
                {
                  !review && (
                    <Box display="flex" mt={2} justifyContent="space-between">
                        {
                          parseInt(activeStep.position) > 0 ? (
                            <Button variant="contained" color="primary" onClick={handleBack}>
                              Back
                            </Button>  
                          ) :
                          (
                            <Box></Box>
                          )
                        }
                        <Button type="submit" variant="contained" color="primary" disabled={allowSubmit || !!isEditMode}>
                            Next
                        </Button>
                    </Box> 
                  )
                }
                
            </Box>
        </form>
    </Box>
  );
};

export default DynamicForm;
