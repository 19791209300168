import React, { useState, useEffect, useContext } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Box,
  Container,
  IconButton,
  TextField,
  Card,
  Button
} from '@mui/material';

import { Icon } from '@iconify/react';

import { styled } from '@mui/material/styles';
import SwornDialog from '../../../../../../dialog/SwornDialog';
import { AWSURI } from '../../../../../../../api/utils/config';
import SecureLS from 'secure-ls'
import DynamicForm from './DynamicForm';
import {post_application_form} from 'api/private/crm';
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import { set } from 'lodash';

const ls = new SecureLS({encodingType: 'aes'})

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  position: 'relative',
  paddingTop: 42,
  paddingBottom: 42,
  display: 'flex',
  alignItems: 'center'
}));

const image_bucket = AWSURI;
const defaultSteps = [
  {
    label: 'Consent',
    formLayout: []
  },
  {
    label: 'Personal Information',
    formLayout: []
  },
  {
    label: 'Education background',
    formLayout: []
  },
  {
    label: 'Family background',
    formLayout: []
  },
  {
    label: 'Working experience',
    formLayout: []
  },
  {
    label: 'Medication information',
    formLayout: []
  }
];

const ApplicationForm = ({ data, setNewApplicantCred }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState(defaultSteps);
  const [finalFormData, setFinalFormData] = useState([]);
  const [skipped, setSkipped] = useState(new Set());
  const [isLoading, setLoading] = useState(false);
  const [isSworn, setSworn] = useState(false); // form data
  const [open, setOpenSworn] = useState(false); // dialog state
  const [user, setUser] = useState([]);
  const [review, setReview] = useState(false);
  const [fields, setFields] = useState([])
  const [newFieldType, setNewFieldType] = useState('text')
  const context = useContext(EditModeContext);
  const { isEditMode } = context || {};
  const company = ls.get('company') || window.location.hostname.split('.')[0];

  useEffect(() => {
    const localSteps = ls.get('crmApplySteps');
    const stepsFinal = localSteps ? localSteps : data || defaultSteps;
    setSteps(stepsFinal);
  }, [data]);


  const handleSworn = () => {
    setSworn(true);
    setOpenSworn(false);
  };

  const openSworn = () => {
    setOpenSworn(true);
  };

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const addStep = (index) => {
    const updatedSteps = [...steps, {
      label: 'New step',
      formLayout: []}];
    setSteps(updatedSteps);
    ls.set('crmApplySteps', updatedSteps);
  };

  const removeStep = (index) => {
    const updatedSteps = [...steps];
    updatedSteps.splice(index, 1);
    setSteps(updatedSteps);
    ls.set('crmApplySteps', updatedSteps);
  };

  const moveStepRight = (index) => {
    const updatedSteps = [...steps];
    const stepToMove = updatedSteps[index];
    const nextIndex = (index + 1) % steps.length;
    
    updatedSteps[index] = updatedSteps[nextIndex];
    updatedSteps[nextIndex] = stepToMove;
    
    setSteps(updatedSteps);
  };

  const moveStepLeft = (index) => {
    const updatedSteps = [...steps];
    const stepToMove = updatedSteps[index];
    let nextIndex = (index - 1) % steps.length;
    if (nextIndex < 0) {
      nextIndex = steps.length - 1;
    }
    updatedSteps[index] = updatedSteps[nextIndex];
    updatedSteps[nextIndex] = stepToMove;
    
    setSteps(updatedSteps);
  };

  const handleSubmit = async (formData, stepLabel) => {
    if (activeStep === steps.length - 1) {
      setReview(true);
      handleNext();
      return;
    } else if (activeStep === steps.length){
      localStorage.removeItem('credentialsDownloaded');
      try {
        let data = new FormData();
        // put email address here
        const emailAddress = finalFormData.find(item => item.step === "Personal Information")?.formData["person_email"] || '';
        data.append('email', emailAddress);
        data.append('company', company);
        // finalFormData.forEach((item) => {
        //   data.append(item.step, JSON.stringify(item.formData));
        // })
        finalFormData.forEach((item) => {
          Object.entries(item.formData).forEach(([key, value]) => data.append(key, value));
        })
        if (emailAddress.toString().trim() === '') {
           throw new Error('Email address is required');
        }
        if (company.toString().trim() === '') {
           throw new Error('Company is required');
        }
        const response = await post_application_form(data);
        if (response.status === 200){
          alert('Form Submitted! Please wait for approval.');
          // const jsonData = JSON.parse(response.data.data)
          // const personalInfo = JSON.parse(jsonData.Personal_Information);
          // const address = `${personalInfo["Blk # /Lot # / House Number"]} ${personalInfo["Street name / Building Name"]}, ${personalInfo["City"]} ${personalInfo["Zip Code"]}`;
          // fetch(
          //   'https://discord.com/api/webhooks/1003873664011337819/oCO_8ZEwNcNyPKrDj9FXY7-BOu6F9iS46A75RG45D-xEUM5iZHsNtNQvGFe-oJlmTpfh',
          //   {
          //     method: 'post',
          //     headers: {
          //       'Content-Type': 'application/json',
          //     },
          //     body: JSON.stringify({
          //       // the username to be displayed
          //       username: 'APEX Notification',
          //       // the avatar to be displayed
          //       avatar_url:
          //         'https://staff.7star.com.ph/media/logos/logo-letter-1.png',
          //       // contents of the message to be sent
          //       content:
          //        "New applicant from 7star \n Email: " +response.data.username+"\n Name: "+ personalInfo["First Name"] +" "+personalInfo["Last Name"]+"\n Contact No: "+personalInfo["Contact No. / Mobile No."]+"\n Address: " + address,
          //     }),
          //   }
          // );

          setNewApplicantCred({username: response.data.username, reference_id: response.data.reference_id});

        } else {
          alert('Something happened, please try again');
          throw new Error(response);
        }
      } catch (error) {
        console.error(error)
      }
      
    } else {
      const updatedFormData = [];
      updatedFormData.push({ step: stepLabel, formData });
      setFinalFormData((prev) => [...prev, ...updatedFormData]);
      handleNext();
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleSetActiveStep = (index) => {
    setActiveStep(index);
  };

  const handleUpdateFields = (fields) => {
    const updatedSteps = [...steps];
    updatedSteps[activeStep].formLayout = fields;
    ls.set('crmApplySteps', updatedSteps);
  }

  const handleUpdateInputFields = (fields) => {
    const updatedSteps = [...steps];
    updatedSteps[activeStep].formLayout = fields;
  }

  const handleStepNameChange = (index, newName) => {
    const updatedSteps = [...steps];
    updatedSteps[index].label = newName;
    setSteps(updatedSteps);
    ls.set('crmApplySteps', updatedSteps);
  };

  return (
    <RootStyle>
    <SwornDialog open={open} onConfirm={handleSworn} />
    <Container maxWidth="lg">
    <Stepper sx={{ flexWrap: 'wrap' }} activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
            stepProps.completed = false;
            }
            return (
            <Step key={index} {...stepProps}>
                <StepLabel
                {...labelProps}
                  onClick={(e) => {
                    e.stopPropagation();
                    {
                      !!isEditMode && handleSetActiveStep(index);
                    }
                  }
                }
                >
                  <Box direction="column">
                    {!!isEditMode ? (
                      <TextField
                        value={step.label}
                        onChange={(e) => handleStepNameChange(index, e.target.value)}
                        InputProps={{
                          style: {
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            wordWrap: 'break-word',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-input': {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }
                        }}
                        multiline
                        maxRows={5}
                      />
                    ) : (
                      <Box direction="row">
                        {step.label}
                      </Box>
                    )
                    }
                    {
                      !!isEditMode && (
                        <Box direction="row">
                          <IconButton onClick={() => moveStepLeft(index)} size="small">
                            <Icon icon="ic:sharp-arrow-back"  style={{color: 'black'}} />
                          </IconButton>
                          <IconButton onClick={() => removeStep(index)} size="small">
                            <Icon icon="lsicon:minus-outline"  style={{color: 'black'}} />
                          </IconButton>
                          <IconButton onClick={() => moveStepRight(index)} size="small">
                            <Icon icon="ic:sharp-arrow-forward"  style={{color: 'black'}} />
                          </IconButton>
                        </Box>
                      )
                    }
                  </Box>
                </StepLabel>
            </Step>
            );
        })}
        <Step key={steps.length}>
          <StepLabel
            onClick={(e) => {
              e.stopPropagation();
              {
                !!isEditMode && handleSetActiveStep(steps.length);
              }
            }}
          >
            <Box direction="column">
              <Box direction="row">
                Review Information
              </Box>
            </Box>
          </StepLabel>
        </Step>
        {!!isEditMode && (
          <Box direction="row" spacing={1} mt={1}>
              <IconButton onClick={addStep} size="medium"><Icon icon="ic:outline-plus"  style={{color: 'black'}} /></IconButton>
          </Box>
        )}
    </Stepper>

    {
      activeStep === steps.length ? (
        <>
          <Box sx={{ display: 'flex', width: '80%', flexDirection: 'column', alignItems: 'center', mx: 'auto', mt: '4vh', mb: '1vh', overflowY: 'auto', maxWidth: '100%' }}>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }} justifyContent={'center'}>
              <Typography variant="h5" align="center" sx={{ mb: '2vh' }}>Review Information</Typography>
            </Box>
            {steps.map((step, index) => (
                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  <DynamicForm onSubmit={handleSubmit} activeStep={step} review={true} updateFields={handleUpdateFields} handleBack={handleBack} />
                </Box>
              )
            )}
            <Box display="flex" mt={2} width={'100%'} justifyContent="space-between">
              <Button variant="contained" color="primary" onClick={handleBack}>
                  Back
              </Button>  
              <Button onClick={handleSubmit} variant="contained" color="primary" disabled={!!isEditMode}>
                  Submit
              </Button>
            </Box> 
          </Box>
        </>
      ) :
      (
        steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <React.Fragment key={index}>
              {activeStep === index && (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%', mx: 'auto', mt: '4vh', mb: '1vh' }}>
                  <Typography variant="h5" sx={{ mb: '2vh' }}>{step.label}</Typography>
                  <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <DynamicForm onSubmit={handleSubmit} activeStep={{...step, position: index}} review={false} updateFields={handleUpdateFields} updateInputFields={handleUpdateInputFields} handleBack={handleBack} />
                  </Box>
                </Box>
              )}
            </React.Fragment>
          );
        })
      )
    }

    </Container>
    </RootStyle>
  );
};

export default ApplicationForm;

