/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, Switch, Redirect} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/_helpers'
import {ContentRoute} from '../../../../_metronic/layout'
import Login from './Login'
import Registration from './Registration'
import ForgotPassword from './ForgotPassword'
import Mobile from './Mobile'
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss'
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  IconButton,
  Divider,
  Grid,
  Popover,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material'

//import ResetPassword from './ResetPassword'

 export function AuthPage() {
  return (
    <div className="d-flex flex-column flex-root">
      {/*begin::Login*/}  
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        id="kt_login"
      >
        {/*begin::Aside*/}
        <Box
          className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
          style={{
            backgroundColor: '#02C3F5',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {/*begin: Aside Container*/}
          <div className="d-flex flex-row-fluid flex-column justify-content-between">
            {/* start:: Aside header */}
            <Link to="/" className="flex-column-auto mt-20 ml-20 pb-lg-0 pb-5">
              <img alt="Logo" className="max-h-70px" style={{width: '160px', objectFit: 'cover', transform: 'scale(2) translateZ(0)', transformOrigin: 'center', clipPath: 'inset(10px 20px 10px 20px)', overflow: 'hidden',}} src={toAbsoluteUrl('/media/logos/new-logo-letter.png')} />
            </Link>
            {/* end:: Aside header */}

            {/* start:: Aside content */}
            <div className="flex-column-auto d-flex flex-column justify-content-center ml-10">
              <h1 className="font-size-h1 mb-2 text-white">Welcome to APEX!</h1>
              <h5 className="font-weight-lighter text-white">Applicant Employee Experience</h5>
            </div>
            {/* end:: Aside content */}

            {/* start:: Aside footer for desktop */}
            <div className="flex-column-auto d-lg-flex justify-content-between mt-10 align-items-center">
              <div className="font-weight-bold	text-white">
                &copy; 2021 Sparkle Star International Corporation
              </div>
              <div className="d-flex">
                <Link to="/terms" className="text-white ml-10">
                  Privacy
                </Link>
                <Link to="/terms" className="text-white ml-10">
                  Legal
                </Link>
                <Link to="/terms" className="text-white ml-10">
                  Contact
                </Link>
              </div>
            </div>
            {/* end:: Aside footer for desktop */}
          </div>
          {/*end: Aside Container*/}
        </Box>
        {/*begin::Aside*/}

        {/*begin::Content*/}
        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
          {/*begin::Content header*/}
          <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
            {/* <span className="font-weight-bold text-dark-50">
                Don't have an account yet?
              </span> */}
            {/* <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                Sign Up!
              </Link> */}
          </div>
          {/*end::Content header*/}

          {/* begin::Content body */}
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <Switch>
              <ContentRoute path="/auth/login" component={Login} />
              <ContentRoute path="/auth/registration" component={Registration} />
              <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />
              <ContentRoute path="/auth/mobile" component={Mobile} />
              <Redirect from="/auth" exact={true} to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
          </div>
          {/*end::Content body*/}

        </div>
        {/*end::Content*/}
      </div>
      {/*end::Login*/}
    </div>
  )
}
