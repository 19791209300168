import React, { useState, useEffect, createContext } from 'react';

import SecureLS from 'secure-ls'

const ls = new SecureLS({encodingType: 'aes'})

const UsersCrmContext = createContext();

// eslint-disable-next-line react/prop-types
const UsersCrmProvider = ({ children }) => {
  const [user, setUser] = useState([]);

  // eslint-disable-next-line camelcase
  const check_login = async () => {
    const company = ls.get('company')
    if(!company) {
      return 'No company found';
    }
    const user = await ls.get(`user_${company}`)

    if (user === undefined || !user) {
      await ls.remove(`user_${company}`);
      return 'loggedout';
    }

    setUser(user);
    return 'loggedin';
  };

  useEffect(() => {
    check_login();
    // eslint-disable-next-line
  }, [window.location.pathname]);

  return <UsersCrmContext.Provider value={{ user, setUser, check_login }}>{children}</UsersCrmContext.Provider>;
};

const UserCrmConsumer = UsersCrmContext.Consumer;

export { UsersCrmContext, UsersCrmProvider, UserCrmConsumer };
