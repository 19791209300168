import React, { useState, useEffect, useCallback } from 'react';
import SecureLS from 'secure-ls';
import { Button, Box, Grid, AppBar, Toolbar, Typography, Divider, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Link, Tooltip, Select, InputLabel, FormControl } from '@mui/material';
import { Icon } from '@iconify/react';
import { styled } from '@mui/system';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TemplateDialog from 'components/crm/modal/template-modal';
import MediaDialog from 'components/crm/modal/media-modal';
import ThemeDialog from 'components/crm/modal/theme-color-modal';
import TrackingGADialog from 'components/crm/modal/trackingGA-modal';
import MainLayout from 'components/crm/templates/first-template/layout';
import Loader from 'components/preloader'; // Import the loader component
import { EditModeContext } from 'context/EditModeContext'; // Import your context
import { PageContext } from 'context/PageContext';
import { fetchAppliedLayout, publishTemplate, unpublishTemplate, editSaveLayout, addGATracker } from 'api/private/crm';
import EditMetaTagsDialog from 'components/crm/meta/EditMetaTagsDialog';
import FAQDialog from 'components/crm/modal/faq-modal'
import OpenChat from 'components/crm/modal/openChat-modal';

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'visible',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  width: '100%',
  height:'auto',
  marginTop: '3vh'
}));

export const CrmEditorPage = () => {
  const [openTemplate, setOpenTemplate] = useState(false);
  const [openMedia, setOpenMedia] = useState(false);
  const [openMeta, setOpenMeta] = useState(false);
  const [openTheme, setOpenTheme] = useState(false)
  const [openTracking, setOpenTracking] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [activeTemplate, setActiveTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [selectedPage, setSelectedPage] = useState('1');

  const savedLayoutData = JSON.parse(localStorage.getItem('layoutData')) || {};
  const [primaryColor, setPrimaryColor] = useState(savedLayoutData.primaryColor || '#000000');
  const [secondaryColor, setSecondaryColor] = useState(savedLayoutData.secondaryColor || '#FFA500');
  const [header, setHeader] = useState({});
  const [footer, setFooter] = useState({});
  const [homePage, setHomePage] = useState({});
  const [careerPage, setCareerPage] = useState({});
  const [contactPage, setContactPage] = useState({});
  const [newsPage, setNewsPage] = useState({});
  const [aboutPage, setAboutPage] = useState({});
  const [applyPage, setApplyPage] = useState({});
  const [openChatMode, setOpenChatMode] = useState(false)
  const [openFAQ, setOpenFAQ] = useState(false)
  const [metaData, setMetaData] = useState({
    charset: 'utf-8',
    viewport: 'width=device-width, initial-scale=1.0',
    site: {
      title: '',
      description: '',
      keywords: ''
    },
    home: {
      title: '',
      description: '',
      keywords: ''
    },
    about: {
      title: '',
      description: '',
      keywords: ''
    },
    career: {
      title: '',
      description: '',
      keywords: ''
    },
    news: {
      title: '',
      description: '',
      keywords: ''
    },
    contact: {
      title: '',
      description: '',
      keywords: ''
    }
  });

  const ls = new SecureLS({ encodingType: 'aes' });

  const companyName = ls.get('company')

  // const url = process.env.REACT_APP_DOMAIN === 'localhost' ? `http://${companyName}.localhost:${process.env.REACT_APP_PORT}` : `https://${companyName}.${process.env.REACT_APP_DOMAIN}`;
  const url = `https://${companyName}.sparkledev.online`;
  const [openPublishURL, setOpenPublishURL] = useState(false);

  const handlePublishURLClose = () => {
    setOpenPublishURL(false);
  };

  const handlePublishURLCopy = () => {
    navigator.clipboard.writeText(url);
    window.alert('Copied to clipboard!');
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  const handleCloseFAQ = () =>{
    setOpenFAQ(false)
  }

  const handleCloseChat = () =>{
    setOpenChatMode(false)
  }

  const getLayout = useCallback(async () => {
      setLoading(true);
      try {
        const response = await fetchAppliedLayout();
        const savedLayoutData = JSON.parse(localStorage.getItem('layoutData')) || {}; // Retrieve local storage data
        const localMetaResponse = ls.get('crmPageMetaTags');
        if(localMetaResponse){
          setMetaData(localMetaResponse);
        }

        if (response) {
          const { templateName, meta, header, footer, homePage, aboutPage, careerPage, contactPage, newsPage, applyPage, primaryColor: fetchedPrimaryColor, secondaryColor: fetchedSecondaryColor } = response.layout;
          // Use colors from local storage first, then fetched data
          const primaryColor = savedLayoutData.primaryColor || fetchedPrimaryColor || '#000000';
          const secondaryColor = savedLayoutData.secondaryColor || fetchedSecondaryColor || '#FFA500';

          setPrimaryColor(primaryColor);
          setSecondaryColor(secondaryColor);
          setHomePage(homePage);
          setCareerPage(careerPage);
          setContactPage(contactPage);
          setNewsPage(newsPage);
          setAboutPage(aboutPage);
          setApplyPage(applyPage);
          if (meta !== undefined) {
          setMetaData(meta);
          }

          setSelectedTemplate(
            <MainLayout
              meta={meta}
              headerData={header}
              footerData={footer}
              homePageData={homePage}
              careerPageData={careerPage}
              contactPageData={contactPage}
              newsPageData={newsPage}
              aboutPageData={aboutPage}
              applyPageData={applyPage}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              handleNavUpdate={handleNavUpdate}
              edit={true}
            />
          );

          const layoutToSave = {
            primaryColor: fetchedPrimaryColor,
            secondaryColor: fetchedSecondaryColor,
            meta,
            header,
            footer,
            homePage,
            careerPage,
            contactPage,
            newsPage,
            aboutPage,
            applyPage
          };

          ls.set('fetchedLayoutData', JSON.stringify(layoutToSave));

          if (response.layout.hasOwnProperty('isPublished')) {
            console.log("isPublished before setting state:", response.layout.isPublished);
            setIsPublished(response.layout.isPublished);
          }
        } else {
          console.error('Layout data is not found in the response.');
        }
      } catch (error) {
        console.error("Failed to fetch the applied layout", error);
      }

    if (!ls.get('mediaAssets')) {
      ls.set('mediaAssets', []);
    }
    
  }, []);

  useEffect( async() => {   
    if(activeTemplate) {
      try {
        const response = await fetchAppliedLayout();
        if (response) {
          getLayout();
        } else {
          console.error('Failed to fetch layout')
        }
      } catch (error) {
        console.error('Error in fetchAppliedLayout:', error)
      } finally {
        setLoading(false);
      }
    } else {
      const response = await fetchAppliedLayout();
      if(response) {
        const { templateName } = response.layout;
        if(!activeTemplate) {
          const templateId = parseInt(templateName.split(' ')[1]);
          setActiveTemplate(templateId);
        }
      }
    }
  },[activeTemplate])


  const handleNavUpdate = (status) =>{
    if(status === true){
      getLayout()
      setLoading(false)
    }
  }

  const handleCloseTemplate = () => {
    setOpenTemplate(false);
  };
  
  const handleCloseMedia = () => {
    setOpenMedia(false);
  };

  const handleCloseTheme = () => {
    setOpenTheme(false);
  };

  const handleCloseMeta = () => {
    setOpenMeta(false);
  };

  const handleCloseTracking = () =>{
    setOpenTracking(false);
  }

  const handleSaveTemplate = () => {
      try {
        const getParsedData = (data) => {
          try {
              return JSON.parse(data);
          } catch (e) {
              return null;
          }
        };
    
        const savedLayoutData = JSON.parse(localStorage.getItem('layoutData')) || {};
        const fetchedLayoutData = getParsedData(ls.get('fetchedLayoutData')) || {}; // Use fetched data if no localStorage

        // Assemble layout data
        const layoutData = {
            primaryColor: savedLayoutData.primaryColor || fetchedLayoutData.primaryColor || '#000000',
            secondaryColor: savedLayoutData.secondaryColor || fetchedLayoutData.secondaryColor || '#FFA500',
            meta: ls.get('crmPageMetaTags') || savedLayoutData?.meta || fetchedLayoutData?.meta || {
              charset: 'utf-8',
              viewport: 'width=device-width, initial-scale=1.0',
              site: {
                title: '',
                description: '',
                keywords: ''
              },
              home: {
                title: '',
                description: '',
                keywords: ''
              },
              about: {
                title: '',
                description: '',
                keywords: ''
              },
              career: {
                title: '',
                description: '',
                keywords: ''
              },
              news: {
                title: '',
                description: '',
                keywords: ''
              },
              contact: {
                title: '',
                description: '',
                keywords: ''
              },
              apply: {
                title: '',
                description: '',
                keywords: ''
              },
              login: {
                title: '',
                description: '',
                keywords: ''
              }
            },
            header: {
                headerImages: ls.get('crmMainHeaderImage') || savedLayoutData?.header?.logo || fetchedLayoutData?.header?.logo || ['logo'],
                links: getParsedData(ls.get('crmMainHeaderLinks')) || savedLayoutData?.header?.links || fetchedLayoutData?.header?.links || [],
                applyNowButtonText: ls.get('crmMainHeaderApplyNowButtonText') || savedLayoutData?.header?.applyNowButtonText || fetchedLayoutData?.header?.applyNowButtonText || 'Apply Now',
            },
            footer: {
                footerImages: ls.get('crmMainFooterImage') || savedLayoutData?.footer?.logo || fetchedLayoutData?.footer?.logo || ['logo', 'wavy-two'],
                officeAddress: ls.get('crmMainFooterTextFields')?.split('|')[0] || savedLayoutData?.footer?.officeAddress || fetchedLayoutData?.footer?.officeAddress || 'Default Address',
                emailAddress: ls.get('crmMainFooterTextFields')?.split('|')[1] || savedLayoutData?.footer?.emailAddress || fetchedLayoutData?.footer?.emailAddress || 'default@example.com',
                phoneNumber: ls.get('crmMainFooterTextFields')?.split('|')[2] || savedLayoutData?.footer?.phoneNumber || fetchedLayoutData?.footer?.phoneNumber || '123-456-7890',
                footerText: ls.get('crmMainFooterTextFields')?.split('|')[3] || savedLayoutData?.footer?.footerText || fetchedLayoutData?.footer?.footerText || 'Default Footer Text',
                iconLink: ls.get('crmMainFooterHrefLinks') || fetchedLayoutData?.footer?.iconLink || savedLayoutData?.footer?.iconLink || [{"icon":0,"alt":"Facebook","link":"https://www.facebook.com/syzygystaffing"}],
            },
            homePage: {
                landingBusiness: {
                    applyOnlineText: ls.get('crmLandingBusinessApplyOnlineText') || savedLayoutData?.homePage?.landingBusiness?.applyOnlineText || fetchedLayoutData?.homePage?.landingBusiness?.applyOnlineText || 'Apply online',
                    employeeLoginText: ls.get('crmLandingBusinessEmployeeLoginText') || savedLayoutData?.homePage?.landingBusiness?.employeeLoginText || fetchedLayoutData?.homePage?.landingBusiness?.employeeLoginText || 'Employee Login',
                    applyOnlineButton: ls.get('crmLandingBusinessApplyOnlineButton') || savedLayoutData?.homePage?.landingBusiness?.applyOnlineButton || fetchedLayoutData?.homePage?.landingBusiness?.applyOnlineButton || {},
                    employeeLoginButton: ls.get('crmLandingBusinessEmployeeLoginButton') || savedLayoutData?.homePage?.landingBusiness?.employeeLoginButton || fetchedLayoutData?.homePage?.landingBusiness?.employeeLoginButton || {},
                    layoutImage: ls.get('crmLandingBusinessImage') || savedLayoutData?.homePage?.landingBusiness?.layoutImage || fetchedLayoutData?.homePage?.landingBusiness?.layoutImage || [],
                },
                landingCenterText: {
                    mainText: ls.get('crmLandingCenterMainText') || savedLayoutData?.homePage?.landingCenterText?.mainText || fetchedLayoutData?.homePage?.landingCenterText?.mainText || 'Grow with us. Start your job search here',
                },
                landingCommitment: {
                    commitmentTitle: ls.get('crmLandingCommitmentTitle') || savedLayoutData?.homePage?.landingCommitment?.commitmentTitle || fetchedLayoutData?.homePage?.landingCommitment?.commitmentTitle || 'Our Commitment',
                    commitmentDescription: ls.get('crmLandingCommitmentDescription') || savedLayoutData?.homePage?.landingCommitment?.commitmentDescription || fetchedLayoutData?.homePage?.landingCommitment?.commitmentDescription || 'We foster ongoing employee involvement...',
                    backgroundImage: ls.get('crmLandingCommitmentImage') || savedLayoutData?.homePage?.landingCommitment?.backgroundImage || fetchedLayoutData?.homePage?.landingCommitment?.backgroundImage || [],
                },
                landingNeedManpower: {
                    needManpowerText: ls.get('crmLandingNeedManpowerText') || savedLayoutData?.homePage?.landingNeedManpower?.needManpowerText || fetchedLayoutData?.homePage?.landingNeedManpower?.needManpowerText || 'Looking for Staffing Services?',
                    contactButton: ls.get('crmLandingNeedManpowerContactButton') || savedLayoutData?.homePage?.landingNeedManpower?.contactButton || fetchedLayoutData?.homePage?.landingNeedManpower?.contactButton || {},
                },
                landingTeam: {
                    welcomeTitle: ls.get('crmLandingTeamWelcomeTitle') || savedLayoutData?.homePage?.landingTeam?.welcomeTitle || fetchedLayoutData?.homePage?.landingTeam?.welcomeTitle || 'Welcome to Lorem Ipsum',
                    welcomeDescription: ls.get('crmLandingTeamWelcomeDescription') || savedLayoutData?.homePage?.landingTeam?.welcomeDescription || fetchedLayoutData?.homePage?.landingTeam?.welcomeDescription || 'Lorem Ipsum Staffing Resources Agency Corporation...',
                    whyTitle: ls.get('crmLandingTeamWhyTitle') || savedLayoutData?.homePage?.landingTeam?.whyTitle || fetchedLayoutData?.homePage?.landingTeam?.whyTitle || 'Why Lorem Ipsum',
                    whyDescription: ls.get('crmLandingTeamWhyDescription') || savedLayoutData?.homePage?.landingTeam?.whyDescription || fetchedLayoutData?.homePage?.landingTeam?.whyDescription || 'At Lorem Ipsum Staffing, we understand...',
                    teamImages: ls.get('crmLandingTeamImage') || savedLayoutData?.homePage?.landingTeam?.teamImages || fetchedLayoutData?.homePage?.landingTeam?.teamImages || [],
                },
              },
              careerPage: {
                  careerHero: {
                      title: ls.get('crmCareerHeroText') || savedLayoutData?.careerPage?.careerHero?.title || fetchedLayoutData?.careerPage?.careerHero?.title || 'Career Opportunities',
                      backgroundImage: ls.get('crmCareerHeroImage') || savedLayoutData?.careerPage?.careerHero?.backgroundImage || fetchedLayoutData?.careerPage?.careerHero?.backgroundImage || 'default-career-hero.jpg',
                  },
                  careerTable: getParsedData(ls.get('crmCareerTableData')) || savedLayoutData?.careerPage?.careerTable || fetchedLayoutData?.careerPage?.careerTable || [],
                  careerHowItWorks: {
                    steps: getParsedData(ls.get('crmCareerHowSteps')) || savedLayoutData?.careerPage?.careerHowItWorks?.map((item) => item.title )|| fetchedLayoutData?.careerPage?.careerHowItWorks?.map((item) => item.title) || [],
                    icons: ls.get('crmCareerHowIcons') || savedLayoutData?.careerPage?.careerHowItWorks?.map((item) => item.icon) || fetchedLayoutData?.careerPage?.careerHowItWorks?.map((item) => item.icon) || [],
                  },
                  crmCareerHowText: ls.get('crmCareerHowText') || savedLayoutData?.careerPage?.crmCareerHowText || fetchedLayoutData?.careerPage?.crmCareerHowText || 'How it Works',
              },
              contactPage: {
                contactHero: {
                    title: ls.get('crmContactHeroText') || savedLayoutData?.contactPage?.contactHero?.title || fetchedLayoutData?.contactPage?.contactHero?.title || 'Contact Us',
                    backgroundImage: ls.get('crmContactHeroImage') || savedLayoutData?.contactPage?.contactHero?.backgroundImage || fetchedLayoutData?.contactPage?.contactHero?.backgroundImage || 'default-contact-image.jpg',
                },
                contactForm: {
                    title: ls.get('crmContactFormText') || savedLayoutData?.contactPage?.contactForm?.title || fetchedLayoutData?.contactPage?.contactForm?.title || 'Message Us',
                    nameFieldLabel: ls.get('crmContactFormNameField') || savedLayoutData?.contactPage?.contactForm?.nameFieldLabel || fetchedLayoutData?.contactPage?.contactForm?.nameFieldLabel || 'Name',
                    emailFieldLabel: ls.get('crmContactFormEmailField') || savedLayoutData?.contactPage?.contactForm?.emailFieldLabel || fetchedLayoutData?.contactPage?.contactForm?.emailFieldLabel || 'Email Address',
                    contactFieldLabel: ls.get('crmContactFormContactField') || savedLayoutData?.contactPage?.contactForm?.contactFieldLabel || fetchedLayoutData?.contactPage?.contactForm?.contactFieldLabel || 'Contact No.',
                    messageFieldLabel: ls.get('crmContactFormMessageField') || savedLayoutData?.contactPage?.contactForm?.messageFieldLabel || fetchedLayoutData?.contactPage?.contactForm?.messageFieldLabel || 'Message',
                    submitButtonText: ls.get('crmContactFormSubmitText') || savedLayoutData?.contactPage?.contactForm?.submitButtonText || fetchedLayoutData?.contactPage?.contactForm?.submitButtonText || 'Send',
                    contactAddFields: ls.get('crmContactFieldAdded') || savedLayoutData?.contactPage?.contactForm?.contactAddFields || fetchedLayoutData?.contactPage?.contactForm?.contactAddFields || []
                }
              },
              newsPage: {
                newsHero: {
                    title: ls.get('crmNewsHeroText') || savedLayoutData?.newsPage?.newsHero?.title || fetchedLayoutData?.newsPage?.newsHero?.title || 'News and Events',
                    backgroundImage: ls.get('crmNewsHeroImage') || savedLayoutData?.newsPage?.newsHero?.backgroundImage || fetchedLayoutData?.newsPage?.newsHero?.backgroundImage || 'default-news-hero.jpg',
                },
                newsCalendar: ls.get('crmNewsEvents') || savedLayoutData?.newsPage?.newsCalendar?.newsAndEvents || fetchedLayoutData?.newsPage?.newsCalendar?.newsAndEvents || [
                    {"date": "2022-01-01", "event": "New Year's Day", "description": "New Year's Day is a federal holiday in the United States."},
                    {"date": "2022-02-14", "event": "Valentine's Day", "description": "Valentine's Day is a special day for couples to celebrate their love."},
                    {"date": "2022-03-17", "event": "St. Patrick's Day", "description": "St. Patrick's Day is a day to celebrate Irish heritage and culture."},
                    {"date": "2022-04-15", "event": "Good Friday", "description": "Good Friday is a Christian holiday that commemorates the crucifixion of Jesus Christ."},
                    {"date": "2022-05-01", "event": "May Day", "description": "May Day is a traditional spring holiday in many countries."},
                    {"date": "2022-06-15", "event": "Father's Day", "description": "Father's Day is a special day to celebrate fathers and father figures."},
                    {"date": "2022-07-01", "event": "Canada Day", "description": "Canada Day is a national holiday in Canada."},
                    {"date": "2022-08-15", "event": "Assumption Day", "description": "Assumption Day is a Catholic holiday that commemorates the assumption of the Virgin Mary into heaven."},
                    {"date": "2022-09-01", "event": "Labor Day", "description": "Labor Day is a federal holiday in the United States that honors the contributions and achievements of American workers."},
                    { "date": "2022-10-01", "event": "Thanksgiving Day", "description": "Thanksgiving Day is a national holiday in the United States that celebrates the harvest and expresses gratitude for the blessings of the past year."},
                    { "date": "2022-11-01", "event": "Halloween", "description": "Halloween is a popular holiday in the United States that is celebrated on October 31st."},
                    { "date": "2022-12-25", "event": "Christmas Day", "description": "Christmas Day is a federal holiday in the United States that commemorates the birth of Jesus Christ."},
                  ],
              },
              aboutPage: {
                aboutHero: {
                  backgroundImage: ls.get('crmAboutUsHeroImage') || savedLayoutData?.aboutPage?.aboutHero?.backgroundImage || fetchedLayoutData?.aboutPage?.aboutHero?.backgroundImage || '',
                  title: ls.get('crmAboutUsHeroText') || savedLayoutData?.aboutPage?.aboutHero?.title || fetchedLayoutData?.aboutPage?.aboutHero?.title || 'About Us',
                },
                aboutCenterText: {
                  title: ls.get('crmAboutUsCenterTextTitle') || savedLayoutData?.aboutPage?.aboutCenterText?.title || fetchedLayoutData?.aboutPage?.aboutCenterText?.title || 'History',
                  text: ls.get('crmHistory') || savedLayoutData?.aboutPage?.aboutCenterText?.text || fetchedLayoutData?.aboutPage?.aboutCenterText?.text || 'Lorem Ipsum history and background text.',
                },
                aboutContents: {
                  vision: ls.get('crmVision') || savedLayoutData?.aboutPage?.aboutContents?.vision || fetchedLayoutData?.aboutPage?.aboutContents?.vision || 'Our vision is to provide the best staffing solutions...',
                  mission: ls.get('crmMission') || savedLayoutData?.aboutPage?.aboutContents?.mission || fetchedLayoutData?.aboutPage?.aboutContents?.mission || [
                    'Deliver excellent human resources services.',
                    'Provide innovative recruitment solutions.',
                    'Ensure client satisfaction through dedicated support.',
                  ],
                },
                aboutBanner: {
                  backgroundImage: ls.get('crmAboutBanner') || savedLayoutData?.aboutPage?.aboutBanner?.backgroundImage || fetchedLayoutData?.aboutPage?.aboutBanner?.backgroundImage || '',
                },
                aboutServices: {
                  title: ls.get('crmAboutTextOurServices') || savedLayoutData?.aboutPage?.aboutServices?.title || fetchedLayoutData?.aboutPage?.aboutServices?.title || 'Our Services',
                  services: ls.get('crmAboutUsServicesImages') || savedLayoutData?.aboutPage?.aboutServices?.services || fetchedLayoutData?.aboutPage?.aboutServices?.services || [],
                  backgroundImage: ls.get('crmAboutUsServicesBackgroundImages') || savedLayoutData?.aboutPage?.aboutServices?.backgroundImage || fetchedLayoutData?.aboutPage?.aboutServices?.backgroundImage || '',
                },
              },
              applyPage: {
                applyHero: {
                  backgroundImage: ls.get('crmApplyHeroImage') || savedLayoutData?.applyPage?.applyHero?.backgroundImage || fetchedLayoutData?.applyPage?.applyHero?.backgroundImage || '',
                  title: ls.get('crmApplyHeroText') || savedLayoutData?.applyPage?.applyHero?.title || fetchedLayoutData?.applyPage?.applyHero?.title || 'Apply Now',
                },
                applySteps: ls.get('crmApplySteps') || savedLayoutData?.applyPage?.applySteps || fetchedLayoutData?.applyPage?.applySteps || [],
              }
        };

        console.log('Sending layout data:', layoutData);

        editSaveLayout(layoutData).then(response => {
            if (response) {
                console.log('Layout data saved successfully:', response);
            } else {
                console.error('Failed to save layout data');
            }
        });
    } catch (error) {
        console.error('Error during handleEditMode:', error);
    }
  }

  const handleEditMode = async () => {
    setIsEditMode(prev => {
        if (prev) {
          handleSaveTemplate()
        }
        return !prev;
    });
};

  const handlePublish = async() =>{
    const publishing = await publishTemplate()
    console.log(publishing)
    if(publishing){
      setIsPublished(true);
      window.alert("Successfully published");
    }else{
      window.alert("Unsuccessfull");
    }
  }

  const handleUnpublish = async () => {
    const unpublishing = await unpublishTemplate(); 
    if (unpublishing) {
      setIsPublished(false);  
      window.alert("Successfully unpublished");
    } else {
      window.alert("Unsuccessful");
    }
  };

  const handleMETADataSave = (metaTags) => {
    delete metaTags['_id']
    setMetaData(metaTags);
    const layoutData = {
      meta: metaTags
    }
    console.log('check meta: ', layoutData)
    editSaveLayout(layoutData).then(response => {
      if (response) {
          console.log('Layout data saved successfully:', response);
          const refresh = activeTemplate
          setActiveTemplate(refresh)
      } else {
          console.error('Failed to save layout data');
      }
    });
  }
  
  const handleSaveGA = async (data) =>{
    const addGA = await addGATracker(data)
    if(addGA){
      window.alert('Update GA tracker Successfully!')
    }
  }

  const [themeColors, setThemeColors] = useState({
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
  });
  
  const theme = createTheme({
    palette: {
      primary: {
        main: themeColors.primaryColor,
      },
      secondary: {
        main: themeColors.secondaryColor,
      },
    },
  });

  useEffect(() => {
    const savedLayoutData = JSON.parse(localStorage.getItem('layoutData')) || {};
    const initialPrimaryColor = savedLayoutData.primaryColor || primaryColor;
    const initialSecondaryColor = savedLayoutData.secondaryColor || secondaryColor;
  
    setThemeColors({ primaryColor: initialPrimaryColor, secondaryColor: initialSecondaryColor });
  }, [primaryColor, secondaryColor]);

  const handleSaveTheme = useCallback((localPrimaryColor, localSecondaryColor) => {
    setThemeColors({ primaryColor: localPrimaryColor, secondaryColor: localSecondaryColor });

    setTimeout(() => {
      handleSaveTemplate();
    }, 500);

  })

  const handleSelectPage = (page) => {
    setSelectedPage(page);
  }

  const handleActiveTemplate = (template) => {
    setActiveTemplate(template);
    if(template === null){
      setSelectedTemplate(null);
    }
  }

  return (
    <>
    <Dialog
      open={openPublishURL}
      onClose={handlePublishURLClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Publish Control"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Your site's URL: </Typography>
            <Link href={url} target="_blank" rel="noopener noreferrer" sx={{wordBreak: 'break-all'}}>{url}</Link>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
                    const savedLayoutData = JSON.parse(localStorage.getItem('layoutData')) || {};
                    const layoutData = {
                      primaryColor: savedLayoutData.primaryColor || primaryColor,
                      secondaryColor: savedLayoutData.secondaryColor || secondaryColor,
                      header,
                      footer,
                      homePage,
                      careerPage,
                      contactPage,
                      newsPage,
                      aboutPage,
                    };
                    localStorage.setItem('layoutData', JSON.stringify(layoutData));
                    if (isPublished) {
                      handleUnpublish();
                    } else {
                      handlePublish();
                    }
                  }} color={isPublished ? "error" : "success"}>
          {isPublished ? " Unpublish" : " Publish"}
        </Button>
        <Button onClick={handlePublishURLCopy} color="primary">
          Copy
        </Button>
        <Button onClick={handlePublishURLClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
      <Grid container sx={{ width: '100%', justifyContent: 'space-between', gap: 2 }}>
        <Grid item sx={{left: 0}}>
          <Grid container direction="row" spacing={2}>
            <Grid item>
            <Tooltip title={"Here you can change the template of your site"} placement="top">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenTemplate(true)}
                endIcon={<Icon icon="gg:template" width="2rem" height="2rem" style={{color: 'white'}} />}
              >
                Templates
              </Button>
              </Tooltip>
            </Grid>
            <Grid item>
            <Tooltip title={"Here you can change the color of the buttons, some backgrounds, etc."} placement="top">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenTheme(true)}
                endIcon={<Icon icon="arcticons:vivo-themes" width="2rem" height="2rem"  style={{color: 'white'}} />}
              >
                Theme Color
              </Button>
            </Tooltip>
            </Grid>
            <Grid item>
            <Tooltip title={"You can find the images that you have used to edit your site here, or you can save new images here for future use in your site"} placement="top">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenMedia(true)}
                style={{
                  width: "auto",
                  transition: "width 0.3s ease",  
                  overflow: "hidden",             
                  whiteSpace: "nowrap",          
                  justifyContent: "flex-start",
                  paddingRight: "16px", 
                }}
                endIcon={<Icon icon="material-symbols-light:perm-media-outline" width="2rem" height="2rem" style={{color: 'white'}} />}
              >
                {'Media Library'}
              </Button>
            </Tooltip>
            </Grid>
            <Grid item>
            <Tooltip title={"Click to start editing the meta tags of your site to improve search engine optimization (SEO) of your site"} placement="top">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenMeta(true)}
                style={{
                  width: "auto", 
                  transition: "width 0.3s ease",  
                  overflow: "hidden",             
                  whiteSpace: "nowrap",          
                  justifyContent: "flex-start",
                  paddingRight:"16px", 
                }}
                endIcon={<Icon icon="icon-park-outline:seo" width="2rem" height="2rem" style={{color: 'white'}} />}
              >
                {'Meta Tags'}
              </Button>
            </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ right: 0}}>
          <Grid container direction="row" spacing={2}>
           {selectedTemplate && (
            <>
              <Grid item>
                <FormControl fullWidth>
                <InputLabel id="page-simple-select-label">Select Page</InputLabel>
                <Select
                  labelId="page-simple-select-label"
                  label="Select Page"
                  value={selectedPage}
                  onChange={(e) => handleSelectPage(e.target.value)}
                  displayEmpty={false}
                  inputProps={{ 'aria-label': 'Without label' }}
                  style={{ minWidth: 120, marginRight: '1rem' }}
                >
                  <MenuItem value="" disabled>Select Page</MenuItem>
                  <MenuItem value="1">Home</MenuItem>
                  <MenuItem value="2">About Us</MenuItem>
                  <MenuItem value="3">Careers</MenuItem>
                  <MenuItem value="4">News & Events</MenuItem>
                  <MenuItem value="5">Contact Us</MenuItem>
                  <MenuItem value="6">Apply</MenuItem>
                  <MenuItem value="7">Login</MenuItem>
                  <MenuItem value="8">Exam</MenuItem>
                </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Tooltip title={!!isEditMode ? "Click to save the changes made in the template" : "Click to be able to edit the template"} placement="top">
                  <Button
                    variant="contained"
                    color={isEditMode ? "success" : "secondary"} 
                    onClick={handleEditMode}
                    style={{
                      width: "auto",  
                      transition: "width 0.3s ease",  
                      overflow: "hidden",             
                      whiteSpace: "nowrap",          
                      justifyContent: "flex-start", 
                      paddingRight: "16px",  
                    }}
                    endIcon={<Icon icon={!!isEditMode ? "ic:sharp-edit-off" : "ic:outline-edit"} width="2rem" height="2rem" style={{color: 'white'}} />}
                  >
                    {!!isEditMode ? <>Save</> : <>Edit</>}
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
              <div>
              <Tooltip title={"This is where you can find whether you need to publish your template to the site, see the preview of the site, or track the SEO and analytics of your site"} placement="top">
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    width: "auto",  
                    transition: "width 0.3s ease",  
                    overflow: "hidden",             
                    whiteSpace: "nowrap",          
                    justifyContent: "flex-end", 
                  }}
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  disableElevation
                  onClick={handleClick}
                  endIcon={<Icon icon={"ic:sharp-keyboard-arrow-down"} width="2rem" height="2rem" style={{color: 'white'}} />}
                >
                  Manage Site
                </Button>
              </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseMenu}
                >
                  <Tooltip title={"Click to see what your site would look like"} placement="top">
                    <MenuItem
                    onClick={() => {
                      const savedLayoutData = JSON.parse(localStorage.getItem('layoutData')) || {};
                      const layoutData = {
                        primaryColor: savedLayoutData.primaryColor || '#000000',
                        secondaryColor: savedLayoutData.secondaryColor || '#FFA500',
                        header,
                        footer,
                        homePage,
                        careerPage,
                        contactPage,
                        newsPage,
                        aboutPage,
                      };
                      localStorage.setItem('layoutData', JSON.stringify(layoutData)); 
                      window.open('/#/preview', '_blank'); 
                    }}>
                      <Icon icon="icon-park-outline:preview-open" width="2rem" height="2rem" style={{ color: 'black', marginRight: '0.5rem' }}/>
                      {'Preview'}
                    </MenuItem>
                  </Tooltip>
                  <Tooltip title={"Click if you are ready to publish your site or just want to get/access the url of your site"} placement="top">
                    <MenuItem
                    onClick={() => setOpenPublishURL(true)}>
                      <Icon
                        icon={"material-symbols:publish"}
                        width="2rem"
                        height="2rem"
                        style={{ color: 'black', marginRight: '0.5rem' }}
                      />
                      {"Publish Control"}
                    </MenuItem>
                  </Tooltip>
                  <Tooltip title={"Click to know how to track your site in google analytics or input the tracking code"} placement="top">
                  <MenuItem
                   onClick={() => setOpenTracking(true)}><Icon icon="ant-design:signal-filled" style={{ color: "black", marginRight: '0.5rem' }}/>{"GA-Tracking Code"}</MenuItem>
                   </Tooltip>
                   <Tooltip title={"Find answers to frequently asked questions here."} placement="top">
                  <MenuItem
                   onClick={() => setOpenFAQ(true)}><Icon icon="mdi:faq" style={{ color: "black", marginRight: '0.5rem' }}/>{"FAQs"}</MenuItem>
                   </Tooltip>
                   <Tooltip title={"Find answers to frequently asked questions here."} placement="top">
                  <MenuItem
                   onClick={() => setOpenChatMode(true)}><Icon icon="ic:twotone-help" style={{ color: "black", marginRight: '0.5rem' }}/>{"Need help"}</MenuItem>
                   </Tooltip>
                </Menu>
                </div>
              </Grid>
            </>
           )}
          </Grid>
        </Grid>
      </Grid>

      <ThemeProvider theme={theme}>
        <Box sx={{marginTop: '8px'}}>
          {isEditMode && (
            <AppBar position="static" color="secondary">
              <Toolbar>
                <Typography variant="h6">
                  Edit Mode is On
                </Typography>
              </Toolbar>
            </AppBar>
          )}
        </Box>
        <ContentStyle>
          <EditModeContext.Provider value={{ isEditMode }}>
            <PageContext.Provider value={{ selectedPage }}>
              <EditMetaTagsDialog onClose={handleCloseMeta} open={openMeta} onSave={handleMETADataSave} metaData={metaData}  />
              
              {loading ? <Loader/> : (selectedTemplate ? selectedTemplate : <div>Please select a template.</div>)}
            </PageContext.Provider>
          </EditModeContext.Provider>
        </ContentStyle>
      </ThemeProvider>

      <TemplateDialog
        openTemplate={openTemplate}
        handleCloseTemplate={handleCloseTemplate}
        activeTemplate={activeTemplate}
        setActiveTemplate={handleActiveTemplate}
        setThemeColors={setThemeColors}
        getLayout={getLayout}
        setLoading={setLoading}
      />

      <ThemeDialog 
        openMedia={openTheme}
        handleCloseMedia={handleCloseTheme}
        setThemeColors={setThemeColors}
        handleSaveTheme={handleSaveTheme} 
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      />
      <MediaDialog
        openMedia={openMedia}
        handleCloseMedia={handleCloseMedia}
        eventButton='indexButton'
      />
      <TrackingGADialog 
        openTracking={openTracking}
        handleCloseTracking={handleCloseTracking}
        handleSaveGA={handleSaveGA}
      />
      <FAQDialog 
        openFAQ={openFAQ}
        handleCloseFAQ={handleCloseFAQ}
      />
      <OpenChat 
        openChatMode={openChatMode}
        handleCloseChat={handleCloseChat}
      />
    </>
  );
};
