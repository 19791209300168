import React, { useEffect} from 'react';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button,
    Typography,
    Stack
  } from '@mui/material'
  
  const AgreementDialog = ({ open, onConfirm }) => {

    const handleConfirm = () => {
      console.log('open: ', open);
      onConfirm(open);
    };
  
    return (
      <div>
        <Dialog open={open}>
          <DialogTitle sx={{ textAlign: 'center', pt: 5, pb: 0 }}>
            <Typography variant="h4">{open?.label}</Typography>
          </DialogTitle>
          <DialogContent sx={{ my: 2, mx: 3 }}>
            <DialogContentText>
              <Typography variant="body1" sx={{ textAlign: 'justify', whiteSpace: 'pre-line' }}>
                {open?.description}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: 'block', pb: 5, px: 5 }}>
            <Stack row>
              <Button color="primary" variant="contained" onClick={() => handleConfirm()}>
                Ok
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  
  export default AgreementDialog;
  