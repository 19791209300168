import React, { useEffect, useState, useContext, useRef } from 'react'
// import { useHistory } from 'react-router-dom';
import { AppBar, Toolbar, Container, Button, Box, Link, IconButton, Menu, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import useMediaQuery from '@mui/material/useMediaQuery'
import defaultLogo from '../assets/logo.png'
import { EditModeContext } from 'context/EditModeContext'; // Import the context
import ImageUploadDialog from '../../../image/ImageWithDialog';
import { EditableItemWrapper } from 'components/wrapper/EditableItemWrapper'
import  ImageWithFallback  from 'components/crm/image/ImageWithFallback';
import { EditNavbarDialog } from 'components/crm/modal/navbar-modal'
import { UsersCrmContext } from 'context/UserCrmContext';

import { updateNavbar } from 'api/private/crm'

import SecureLS from 'secure-ls'

const Logo = styled('img')({
  height: '64px',
  width: 'auto',
})

const NavLinks = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  '& a': {
    margin: '0 15px',
    color: '#000000',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  [theme.breakpoints.down('md')]: {
    display: 'none', // Hide links on smaller screens
  },
}))

const DropdownMenu = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
}))

const ls = new SecureLS({ encodingType: 'aes' })

export default function MainHeader({ onNavigate, headerData, handleNavUpdate, loggedIn, handleLoggedIn, handleSignOut }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const context = useContext(EditModeContext);
  const { check_login } = useContext(UsersCrmContext);

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState([logoSrc]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [parentHover, setParentHover] = useState(false);
  const [childHover, setChildHover] = useState(null);
  const [openDialogIndex, setOpenDialogIndex] = useState(false);
  const [dialogValue, setDialogValue] = useState('');
  const oldLabel = useRef(null)
  const navIndex = useRef(null)
  // const history = useHistory();

  useEffect(async () => {
    const result = await check_login();
    if (result === 'loggedin') {
      handleLoggedIn()
    }
  }, [loggedIn]);

  useEffect(() => {
    const storedImage = ls.get('crmMainHeaderImage');
    const backendLogo = storedImage || headerData?.headerImages || [defaultLogo];
    setImageUrl(backendLogo);
  }, [headerData]);

  const logoSrc = headerData?.logo || defaultLogo
  const links = headerData?.links || [
    { label: 'Home', url: 'home' },
    { label: 'About Us', url: 'about' },
    { label: 'Career Opportunities', url: 'career' },
    { label: 'News & Events', url: 'news' },
    { label: 'Contact Us', url: 'contact' },
  ];
  const applyNowButtonText = headerData?.applyNowButtonText || 'Apply Now'

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const { isEditMode } = context || {}

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setImageDialogOpen(true); // Open dialog on image click
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false); // Close dialog
  };

  const handleImageUpload = (newImageUrl) => {
    const updatedImages = [...imageUrl];
    updatedImages[selectedImageIndex] = newImageUrl;
    setImageUrl(updatedImages);
    ls.set('crmMainHeaderImage', updatedImages); // Save the new image in local storage
  };

  const handleDialogClose = () => {
    setOpenDialogIndex(false);
  };

  const handleDialogSave = async (value) => {
    const data = { url: `/${value.toLowerCase().replace(/\s+/g, '')}`, oldLabel: oldLabel.current, newLabel: value }
    const response = await updateNavbar(data)
    if(response){
      window.alert('Successfully Update!')
      // eslint-disable-next-line no-restricted-globals
      // location.reload(true);
      handleNavUpdate(true)
    }

  };

  const handleDialogOpen = (label, index) => {
    setDialogValue(label);
    setOpenDialogIndex(true);
    navIndex.current = index
  }

  const handleLogout = () => {
    const company = ls.get('company');
    if (!company) return;
    ls.remove(`token_${company}`);
    ls.remove(`user_${company}`);
    handleSignOut()
  }


  return (
    <>
      <ImageUploadDialog
        open={imageDialogOpen}
        onClose={handleImageDialogClose}
        onUpload={handleImageUpload}
        currentImage={imageUrl[selectedImageIndex]}
      />
      <EditNavbarDialog
        open={openDialogIndex}
        value={dialogValue}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        onChange={setDialogValue}
      />
      <AppBar position="static" color="transparent" elevation={0}>
        <Container maxWidth="lg">
          <Toolbar sx={loggedIn ? { justifyContent: 'space-between' } : {}} disableGutters>
            {
              !!isEditMode ? (
                <>
                  <EditableItemWrapper onClick={() => handleImageClick(0)} sx={{ display: 'flex', alignItems: 'center' }} hover={parentHover && !childHover} onMouseEnter={() => setParentHover(true)} onMouseLeave={() => setParentHover(false)} editType={'logo'}>
                    <Link>
                      <ImageWithFallback 
                      src={imageUrl[0]}
                      fallbackSrc={defaultLogo} 
                      alt="Logo"
                      sx={{  height: '64px', width: 'auto', minWidth: 50}}
                    />
                    </Link>
                  </EditableItemWrapper>
                </>
              ) : (
                <>
                  <Link href="#" onClick={() => onNavigate('home')} sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImageWithFallback 
                    src={imageUrl[0]}
                    fallbackSrc={defaultLogo} 
                    alt="Logo"
                    sx={{  height: '64px', width: 'auto', minWidth: 50 }}
                  />
                  </Link>
                </>
              )
            }
            {isMobile ? (
              <DropdownMenu>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuClick}>
                  <MenuIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                  {loggedIn ? (
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  ) : (
                    links.map(link => (
                      <MenuItem
                        key={link.label}
                        onClick={() => {
                          onNavigate(link.label.toLowerCase().replace(/\s+/g, link.pageNumber)) // Convert label to a URL-friendly format
                          handleMenuClose()
                        }}
                      >
                        {link.label}
                      </MenuItem>
                    ))
                  )}
                </Menu>
              </DropdownMenu>
            ) : (
                !loggedIn && (
                  <>
                     <NavLinks>
                        {links.map((item, index) => (
                          isEditMode ? (
                            <EditableItemWrapper
                              key={index}
                              onClick={(e) => {
                                e.stopPropagation();
                                oldLabel.current = item.label;
                                handleDialogOpen(item.label, index);
                              }}
                              hover={childHover === index} onMouseEnter={() => setChildHover(index)} onMouseLeave={() => setChildHover(null)}
                              editType={'tab'}
                            >
                              <Link
                                onClick={(e) => {
                                  e.preventDefault();
                                  // onNavigate(item.label, item.pageNumber)
                                  // handleNavigate(item.label)
                                }}
                                underline="none"
                              >
                                {item.label}
                              </Link>
                            </EditableItemWrapper>
                          ) : (
                            <Link
                              key={index}
                              onClick={(e) => {
                                  e.preventDefault();
                                  onNavigate(item.label, item.pageNumber)
                              }}
                              underline="none"
                            >
                              {item.label}
                            </Link>
                          )
                        ))}
                      </NavLinks>
                  </>
                )
            )}

            {!isMobile && (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: theme => theme.palette.primary.main || '#FDC22F',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: theme => theme.palette.primary.dark || '#E6B022',
                    color: '#ffffff',
                  },
                }}
                onClick={() => loggedIn ? handleLogout() : onNavigate('application page', 6)} // Use onNavigate instead of href
              >
                { loggedIn ? 'Sign Out' : applyNowButtonText}
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}
