  
import axios from "axios";
import {API, SECRET} from '../../../../api/utils/config'

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "internal/account/v2/create";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export async function register(email, firstname, lastname, company, password) {
  console.log("register", email, firstname, lastname, company, password)
  const data = new FormData();
  data.append('email', email);
  data.append('firstname', firstname);
  data.append('lastname', lastname);
  data.append('company', company);
  data.append('password', password);

  return fetch(`${API}/${REGISTER_URL}`, {
    method: 'POST',
    headers: {
      'X-API-KEY': `${SECRET}`,
      'Accept': 'multipart/form-data'
    },
    body: data,
  })
    .then(response => response.json())
    .catch(err => {
      console.log("Error Registering Data", err)
      throw err
    });
}


export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}