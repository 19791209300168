import React, { useState } from 'react'
import {Box} from '@mui/material'
import {styled} from '@mui/material/styles'
import { Helmet } from 'react-helmet';
import ApplicationForm from './applicationForm'
import ApplicationSuccess from './applySuccess'
import ApplyHero from './applyHero';
import ApplyContent from './ApplyContent';
// Components


const ContentStyle = styled('div')(({theme}) => ({
  overflow: 'visible',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}))

function MetaTags({ title, description, keywords }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}

export default function ApplyPage({ metaData, applyPageData}) {
  const [newApplicantCred, setNewApplicantCred] = useState({});

  return (
    <>
    <MetaTags title={metaData.title} description={metaData.description} keywords={metaData.keywords} />
    <Box id="move_top">
      <ContentStyle>
        <ApplyHero data={applyPageData?.applyHero} />
        {
          Object.keys(newApplicantCred).length === 0 ? (<ApplicationForm data={applyPageData?.applySteps} setNewApplicantCred={setNewApplicantCred} />) : (<ApplicationSuccess data={newApplicantCred} />)
        }
        <ApplyContent />
      </ContentStyle>
    </Box>
    </>
  )
}