import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, MenuItem, Grid, TextField, FormControl, InputLabel, Select, Checkbox, FormControlLabel, Radio, RadioGroup, Link, Alert, Tooltip, Slider, Typography, Backdrop, CircularProgress  } from '@mui/material';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';

const AddFieldDialog = ({ open, onClose, onSubmit, error, status, message }) => {
  const [data, setData] = useState({
    name: '',
    type: 'text',
    label: '',
    value: '',
    description: '',
    options: [],
    validations: {
      required: false,
      minLength: '',
      maxLength: '',
      pattern: '',
      email: false,
      numeric: false,
      allowedFileTypes: ''
    },
    conditional: {
      enabled: false,
      dependsOn: '', // Field ID/name that this field depends on
      showWhen: '', // Value that triggers showing this field
      type: 'checkbox' // or 'select'
    },
    repeatable: {
      enabled: false,
      maxRows: 1,
      columns: 1,
      columnLabels: [],
      columnNames: []
    },
    style: {
      width: 100,
      textarea: false,
      textarearows: 1
    },
    error: '',
    helperText:''
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleResetForm();
  }, [status, loading])

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    console.log(name, value, checked, type)
    if (name === 'type' && value === 'privacy-checkbox' || name === 'type' && value === 'sworn-checkbox') {
      setData((prevState) => ({
        ...prevState,
        label: value === 'privacy-checkbox' ? 'Privacy Policy' : 'Sworn Statement',
        validations: {
          required: true
        }
      }))
    }
    if (name === 'options'){
      setData((prevState) => ({ ...prevState, options: Array.from(value.split(',')) }));
    } else if (name.startsWith('validations.')) {
      const validationField = name.split('.')[1];
      setData((prevState) => ({
        ...prevState,
        validations: {
          ...prevState.validations,
          [validationField]: type === 'checkbox' ? checked : value
        }
      }));
    } else if (name.startsWith('conditional.')) {
      const conditionalField = name.split('.')[1];
      setData((prevState) => ({ ...prevState, conditional: { ...prevState.conditional,  [conditionalField]: type === 'checkbox' ? checked : value } }));
    } else if (name.startsWith('repeatable.')) {
      const repeatableField = name.split('.')[1];
      setData((prevState) => ({ ...prevState, repeatable: { ...prevState.repeatable,  [repeatableField]: type === 'checkbox' ? checked : value } }));
    } else if (name.startsWith('style.')) {
      const styleField = name.split('.')[1];
      setData((prevState) => ({ ...prevState, style: { ...prevState.style,  [styleField]: type === 'checkbox' ? checked : value } }));
    } else {
      setData((prevState) => ({ ...prevState, [name]: value }));
    }
  };


  const handleResetForm = () => {
    if (status === 'success') setData({
      name: '',
      type: 'text',
      label: '',
      value: '',
      description: '',
      options: [],
      validations: {
        required: false,
        minLength: '',
        maxLength: '',
        pattern: '',
        email: false,
        numeric: false,
        allowedFileTypes: ''
      },
      conditional: {
        enabled: false,
        dependsOn: '', // Field ID/name that this field depends on
        showWhen: '', // Value that triggers showing this field
        type: 'checkbox' // or 'radio'
      },
      repeatable: {
        enabled: false,
        maxRows: 1,
        columns: 1,
        columnLabels: [],
        columnNames: []
      },
      style: {
        width: 100,
        textarea: false,
        textarearows: 1
      },
      error: '',
      helperText:''
    });
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const finalData = {...data}
      if (finalData.repeatable.enabled) {
        finalData.value = [[]]
      }
      await onSubmit(finalData, open + 1);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open !== null} onClose={onClose}>
      {
        loading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )
      }
      <DialogTitle>Add New Field</DialogTitle>
      {status && message && (
        <Grid item xs={12}>
          <Alert severity={status === 'success' ? "success" : status === 'error' ? "error" : "warning"} sx={{ mt: 2 }}>
            {message}
          </Alert >
        </Grid>
      )}
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Field Type</InputLabel>
          <Select
            value={data.type}
            name="type"
            label="Field Type"
            onChange={handleInputChange}
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="label">Label</MenuItem>
            <MenuItem value="select">Select</MenuItem>
            <MenuItem value="image">Image Upload</MenuItem>
            <MenuItem value="divider">Divider</MenuItem>
            <MenuItem value="divider-label">Divider and Label</MenuItem>
            <MenuItem value="next-row">Next Row</MenuItem>
            <MenuItem value="privacy-checkbox">Privacy Policy</MenuItem>
            <MenuItem value="sworn-checkbox">Sworn Statement</MenuItem>
            <MenuItem value="accordion-checkboxes">Accordion</MenuItem>
            <MenuItem value="checkbox">Checkbox</MenuItem>
            <MenuItem value="date-picker">Date Picker</MenuItem>
          </Select>
        </FormControl>
        {(data.type === 'select' || data.type === 'accordion-checkboxes') && (
          <TextField
            fullWidth
            name="options"
            sx={{ mt: 2 }}
            label="Options (comma separated)"
            value={data.options.join(',')}
            onChange={handleInputChange}
          />
        )}
        {
          data.type !== 'divider' && data.type !== 'next-row' && (
            <>
            {
              data.type !== 'label' && data.type !== 'divider-label' && !data.repeatable.enabled && (
                <TextField
                  fullWidth
                  name="name"
                  sx={{ mt: 2 }}
                  label="Field Name"
                  value={data.name}
                  onChange={e => handleInputChange({ target: { name: e.target.name, value: e.target.value.replace(/\s/g, '') }})}
                />
              )
            }
            {
              data.type !== 'image' && data.type !== 'privacy-checkbox' && data.type !== 'sworn-checkbox' && (
                <TextField
                  fullWidth
                  name="label"
                  multiline
                  rows={4}
                  sx={{ mt: 2 }}
                  label="Field Label"
                  value={data.label}
                  onChange={handleInputChange}
              />
              )
            }
            </>
          )
        }
        {(data.type === 'text' || data.type === 'select' || data.type === 'label') && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Width
            </Typography>
            </Grid>
            <Slider
              value={data.style?.width || 100}
              onChange={(e, value) => handleInputChange({ target: { name: 'style.width', value } })}
              name="style.width"
              min={25}
              max={100}
              valueLabelDisplay="auto"
              sx={{ width: '100%', maxWidth: 'unset', mx: 'auto' }}
            />
          </Grid>
        )}
        {data.type !== 'divider' && data.type !== 'divider-label' && data.type !== 'next-row' && (
          <Grid container spacing={2}>
            {data.type !== 'label' && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.validations.required}
                      onChange={handleInputChange}
                      name="validations.required"
                    />
                  }
                  label="Required"
                />
              </Grid>
            )}
            {data.type === 'text' && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data.style?.textarea}
                        onChange={handleInputChange}
                        name="style.textarea"
                      />
                    }
                    label="Text Area"
                  />
                </Grid>
                {(!!data.style?.textarea) && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="number"
                      name="style.textarearows"
                      label="Text Area Rows"
                      onChange={handleInputChange}
                      value={data.style?.textarearows ?? 1}
                      inputProps={{
                        min: 1,
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    name="validations.minLength"
                    label= {data.validations?.validationType === 'numeric' ? "Min Value" : "Min Length"}
                    value={data.validations?.minLength}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    name="validations.maxLength"
                    label={data.validations?.validationType === 'numeric' ? "Max Value" : "Max Length"}
                    value={data.validations?.maxLength}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                    fullWidth
                    multiline
                    rows={4}
                    label="description"
                    name="description"
                    onChange={handleInputChange}  // Add this line
                    value={data.description}  
                  />
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    aria-label="validation-type"
                    name="validations.validationType"
                    value={data.validations?.validationType || 'none'}
                    onChange={handleInputChange}
                    sx={{ '& .MuiRadio-root': { mr: 1 } }}
                  >
                    <FormControlLabel defaultChecked value="none" label="None" control={<Radio />} />
                    <FormControlLabel value="email" label="Email Format" control={<Radio />} />
                    <FormControlLabel value="numeric" label="Numeric Only" control={<Radio />} />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Alert severity="info"><Link
                          href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions"
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="hover"
                        >What is a RegEx? Click here to learn more</Link>
                  </Alert>
                    <TextField
                      fullWidth
                      name="validations.pattern"
                      label={"Custom Pattern (RegEx)"}
                      value={data.validations.pattern}
                      onChange={handleInputChange}
                    />
                </Grid>
              </>
            )}
            {data.type === 'privacy-checkbox' && (
              <Grid item xs={12}>
                <TextField 
                  fullWidth
                  multiline
                  rows={6}
                  label="Privacy Policy"
                  name="description"
                  onChange={handleInputChange}  // Add this line
                  value={data.description}  
                />
              </Grid>
            )}
            {data.type === 'sworn-checkbox' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={6}
                  label="Sworn Statement"
                  name="description"
                  onChange={handleInputChange}  // Add this line
                  value={data.description}  
                />
              </Grid>
            )}
            {data.type === 'image' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="validations.allowedFileTypes"
                  label="Allowed File Types (e.g., .jpg,.png,.jpeg)"
                  value={data.validations.allowedFileTypes}
                  onChange={handleInputChange}
                  helperText="Enter file extensions separated by commas"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControlLabel
                name="conditional.enabled"
                control={
                  <Checkbox
                    name="conditional.enabled"
                    checked={data.conditional.enabled}
                    onChange={handleInputChange}
                  />
                }
                label="Make this field conditional"
              />
            </Grid>
            {data.conditional.enabled && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="conditional.dependsOn"
                    label="Depends on Field Name"
                    value={data.conditional.dependsOn}
                    onChange={handleInputChange}
                    sx={{ mt: 2 }}
                  />
                </Grid>
                <Grid item xs={12}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel>Condition Type</InputLabel>
                  <Select
                    value={data.conditional.type}
                    name="conditional.type"
                    label="Condition Type"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="checkbox">Checkbox</MenuItem>
                    <MenuItem value="radio">Select</MenuItem>
                  </Select>
                </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="conditional.showWhen"
                    label="Show When Value Is"
                    value={data.conditional.showWhen}
                    onChange={handleInputChange}
                    sx={{ mt: 2 }}
                    helperText="For checkbox, use 'true' or 'false', for select, enter the option value"
                  />
                </Grid>
              </>
            )}
            {
              data.type === 'text' && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data.repeatable.enabled}
                        onChange={(e) => handleInputChange({
                          target: {
                            name: 'repeatable.enabled',
                            checked: e.target.checked,
                            type: 'checkbox'
                          }
                        })}
                      />
                    }
                    label="Make this field repeatable"
                  />
                </Grid>
              )
            }
            {data.repeatable.enabled && (
              <>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    name="repeatable.maxRows"
                    label="Maximum Rows Allowed"
                    value={data.repeatable.maxRows}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    name="repeatable.columns"
                    label="Number of Columns"
                    value={data.repeatable.columns}
                    onChange={handleInputChange}
                  />
                </Grid>
                {[...Array(Number(data.repeatable.columns))].map((_, index) => (
                  <Grid item xs={12} key={index}>
                    <TextField
                      fullWidth
                      name={`repeatable.columnLabels[${index}]`}
                      label={`Column ${index + 1} Label`}
                      value={data.repeatable.columnLabels[index] || ''}
                      onChange={(e) => {
                        const newLabels = [...data.repeatable.columnLabels];
                        newLabels[index] = e.target.value;
                        setData(prev => ({
                          ...prev,
                          repeatable: {
                            ...prev.repeatable,
                            columnLabels: newLabels
                          }
                        }));
                      }}
                    />
                    <TextField
                      fullWidth
                      name={`repeatable.columnNames[${index}]`}
                      label={`Column ${index + 1} Name`}
                      value={data.repeatable.columnNames[index] || ''}
                      onChange={(e) => {
                        const newNames = [...data.repeatable.columnNames];
                        newNames[index] = e.target.value;
                        setData(prev => ({
                          ...prev,
                          repeatable: {
                            ...prev.repeatable,
                            columnNames: newNames
                          }
                        }));
                      }}
                    />
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">Add Field</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFieldDialog;