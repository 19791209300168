import {postCrm, getCrm, updateCrm, postCrmImage, deleteCrm, post, postApp} from 'api/utils/requests'
import SecureLS from 'secure-ls'
import { AWSDOCUMENT } from '../../../api/utils/config'

const ls = new SecureLS({encodingType: 'aes'})


const   PUBLISH_TEMPLATE = `api/crm/publish`
const   UNPUBLISH_TEMPLATE = `api/crm/unpublish`
const GET_PUBLISHED = `api/crm/publish`
const ADD_GA_TRACKER = `api/crm/ga-trackercode`
const GET_GA_TRACKER = `api/crm/ga-trackercode/`
const UPDATE_NAVBAR = `api/crm/navbar`
export const activateTemplate = async templateId => {
  try {
    const companyName = ls.get('company')
    if (!companyName) {
      throw new Error('Company name not found in SecureLS.')
    }

    const data = {
      companyName,
      templateId,
    }

    const response = await postCrm(data, 'api/crm/activate-layout')
    if (response) {
      console.log('Layout activated:', response)
      return response
    } else {
      console.error('Failed to activate layout')
      return null
    }
  } catch (error) {
    console.error('Error in activateTemplate:', error)
    return null
  }
}

export const deactivateTemplate = async () => {
  try {
    const companyName = ls.get('company')
    if (!companyName) {
      throw new Error('Company name not found in SecureLS.')
    }

    const data = {
      companyName,
    }

    const response = await postCrm(data, 'api/crm/deactivate-layout')
    if (response) {
      console.log('Layout deactivated:', response)
      return response
    } else {
      console.error('Failed to deactivate layout')
      return null
    }
  } catch (error) {
    console.error('Error in deactivateTemplate:', error)
    return null
  }
}

export const fetchAppliedLayout = async () => {
  try {
    const companyName = ls.get('company')
    if (!companyName) {
      throw new Error('Company name not found in SecureLS.')
    }

    const response = await getCrm(`api/crm/layout/${companyName}`)
    if (response) {
      console.log('Fetched layout:', response)
      return response
    } else {
      console.error('Failed to fetch layout')
      return null
    }
  } catch (error) {
    console.error('Error in fetchAppliedLayout:', error)
    return null
  }
}



export const publishTemplate = async ()=> {
    let companyName = ls.get('company')
    let _url = `${PUBLISH_TEMPLATE}`
    const data = {companyName}
    const response = await updateCrm(data, _url)
    if(response){
      return true
    }
    return false
  }

  export const unpublishTemplate = async () => {
    try {
      const companyName = ls.get('company');
      if (!companyName) {
        throw new Error('Company name not found in SecureLS.');
      }
  
      const data = { companyName };
      const response = await updateCrm(data, UNPUBLISH_TEMPLATE); 
      if (response) {
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error in unpublishTemplate:', error);
      return false;
    }
  };


  export const getPublished = async () => {
    try {
      const companyName = ls.get('company')
      if (!companyName) {
        throw new Error('Company name not found in SecureLS.')
      }
  
      const response = await getCrm(`api/crm/publish/${companyName}`)
      if (response) {
        console.log('Fetched layout:', response)
        return response
      } else {
        console.error('Failed to fetch layout')
        return null
      }
    } catch (error) {
      console.error('Error in fetchAppliedLayout:', error)
      return null
    }
  }

export const editSaveLayout = async layoutData => {
  try {
    const companyName = ls.get('company')
    if (!companyName) {
      throw new Error('Company name not found in SecureLS.')
    }

    const data = {
      companyName,
      ...layoutData,
    }

    const response = await postCrm(data, 'api/crm/edit-layout')
    if (response) {
      console.log('Layout edited and saved:', response)
      return response
    } else {
      console.error('Failed to edit and save layout')
      return null
    }
  } catch (error) {
    console.error('Error in editSaveLayout:', error)
    return null
  }
}

export const checkSubdomain = async subdomain => {
  try {
    console.log('Checking subdomain:', subdomain)
    const response = await getCrm(`api/crm/public/check-subdomain/${subdomain}`)
    if (response) {
      console.log('Fetched layout:', response)
      return response
    } else {
      console.error('Failed to fetch layout')
      return null
    }
  } catch (error) {
    console.error('Error in fetchAppliedLayout:', error)
    return null
  }
}

export const addGATracker = async tracker =>{
  try{
    const companyName = ls.get('company')
    console.log("CompanyName Addtracker =>", companyName)
    const data = {gaTracker: tracker, companyName: companyName}
    const response = await postCrm(data, ADD_GA_TRACKER)
    if(response){
      return response
    }else{
      return null
    }
  }catch(error){
    console.log("Error Saving Data GATracker", error)
  }
}

export const getGATracker = async (companyName) =>{
  try{
    if (!companyName) {
      throw new Error('No subdomain found')
    }
    const response = await getCrm(`api/crm/ga-trackercode/${companyName}`)
    if (response) {
      console.log('Fetched layout:', response)
      return response
    } else {
      console.error('Failed to fetch layout')
      return null
    }
  }catch(error){
    console.log("Error", error)
  }
}

export const uploadImage = async (image, companyName) => {
  try {
    if (!companyName) {
      throw new Error('No subdomain found')
    }
    if (!image) {
      throw new Error('No image found')
    }

    console.log('Uploading image:', image)
    const response = await postCrmImage(image, `api/crm/save-media/${companyName}`)

    if (!response) {
      console.error('Failed to upload image')
      return null
    }

    return response
  } catch (error) {
    console.error('Error in uploadImage:', error)
    return null
  }
}

export const deleteImage = async (imageName, companyName) => {
  try {
    if (!companyName) {
      throw new Error('No subdomain found')
    }
    if (!imageName) {
      throw new Error('No image name found')
    }

    console.log('Deleting image:', imageName)
    const response = await deleteCrm(`api/crm/media/${companyName}/${imageName}`)

    if (!response) {
      console.error('Failed to upload image')
      return null
    }

    return response
  } catch (error) {
    console.error('Error in uploadImage:', error)
    return null
  }
}

export const getImages = async (companyName) => {
  try {
    if (!companyName) {
      throw new Error('No subdomain found')
    }

    const response = await getCrm(`api/crm/media/${companyName}`)

    if (!response) {
      console.error('Failed to get images')
      return null
    }

    return response
  } catch (error) {
    console.error('Error in uploadImage:', error)
    return null
  }
}

export const updateNavbar = async ({..._data})=> {
  let companyName = ls.get('company')
  const {url, oldLabel, newLabel, index} = _data
  const data = {url, oldLabel, newLabel, companyName, index}
  const response = await updateCrm(data, UPDATE_NAVBAR)
  if(response){
    return response
  }
  return false
}

export const request_upload_profile_img = async (_file) => {
  if (!_file) return false;

  const to_base64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const blob = await to_base64(_file);
  // eslint-disable-next-line
  const get_url = await fetch(`${AWSDOCUMENT}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ file_type: _file.type })
  }).then((r) => {
    return r.json();
  });

  if (!get_url) return false;

  const { photoFilename, uploadURL } = get_url;
  const binary = atob(blob.split(',')[1]);

  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }

  const blobData = new Blob([new Uint8Array(array)], {
    type: _file.type
  });

  const result = await fetch(uploadURL, {
    method: 'PUT',
    body: blobData
  }).then((response) => {
    return response;
  });

  if (result.status === 200) {
    return photoFilename;
  } else {
    return false;
  }
}

export const post_application_form = (form_data) => {
  return postApp(form_data, `internal/record/v2/create`, {
    'Accept': 'multipart/form-data'
  });
};

export const request_upload_url = async (_file) => {
  if (!_file) return false;

  const to_base64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const blob = await to_base64(_file);
  // eslint-disable-next-line
  const get_url = await fetch(`${process.env.REACT_APP_AWS_DOCUMENT_URI}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ file_type: _file.type })
  }).then((r) => {
    return r.json();
  });

  if (!get_url) return false;

  const { photoFilename, uploadURL } = get_url;
  const binary = atob(blob.split(',')[1]);

  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }

  const blobData = new Blob([new Uint8Array(array)], {
    type: _file.type
  });

  const result = await fetch(uploadURL, {
    method: 'PUT',
    body: blobData
  }).then((response) => {
    return response;
  });

  if (result.status === 200) {
    console.log(result.url)
    return photoFilename;
  } else {
    return false;
  }
};

export const sign_in_email = (form) => postApp(form, 'internal/v2/auth/login', {});